.btnCommonStyles {
  cursor: pointer;
  border: 0;
  outline: none;
  background: #ebebeb;
  border-radius: 15px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 48px;
  text-transform: capitalize;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  letter-spacing: -0.5px;
  color: #666666;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnCommonStyles:hover {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
}
