.modelDynamic {
  $zIndex: 1000;
  * {
    font-family: Inter;
    font-style: normal;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  button {
    cursor: pointer;
    border: 0;
    outline: none;
    background: #fff;
  }

  .topMost {
    z-index: $zIndex + 100;
    background-color: transparent;
  }
  .bgWhiteTopMost {
    z-index: $zIndex + 100;
    background-color: #fff;
  }
  .mvLevel {
    z-index: $zIndex;
    background-color: transparent;
  }
  .rightSubBox {
    width: 100%;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
  }
  .ck {
    // border: 2px solid red;
  }
  .ck-editor__editable {
    height: 100% !important;
    border: 0 !important;
    // border: 1px solid blue !important;
  }
  .ck-editor__main {
    height: 100% !important;
    min-height: 100% !important;
    // border: 1px solid red !important;
  }
  .ck-content {
    height: 100% !important;
    // border: 1px solid aqua !important;
  }
  // ::-webkit-scrollbar {
  //   width: 4px;
  // }

  // ::-webkit-scrollbar-track {
  //   // background: #f1f1f1;
  //   background: #fff;
  // }

  // ::-webkit-scrollbar-thumb {
  //   background: #888;
  // }

  .MD_components::-webkit-scrollbar {
    width: 2px;
  }
  .MD_components::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .MD_components::-webkit-scrollbar-thumb {
    background: #888;
  }

  #HOTSPOT_DRAWER_ID {
    z-index: $zIndex + $zIndex;
  }
  .__actuality__arModelPopUp * {
    margin: auto !important;
    padding: auto !important;
    box-sizing: content-box !important;
  }
}
