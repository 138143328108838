.exitBtn {
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  display: inline-grid;
  place-items: center;
  outline: none;
  transition: transform 0.5s;
}

.exitBtn.rightAlign.rotate {
  transform: rotateZ(-180deg) translateX(50%) translateY(50%);
}

.exitBtn.leftAlign.rotate {
  transform: rotateZ(-180deg) translateX(50%) translateY(50%);
}
