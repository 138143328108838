.annotBtnWrapper__X {
  /* display: inline-block; */
  position: absolute;
  display: flex;
  align-items: center;
  cursor: pointer;
  /* border: 1px solid red; */
  height: min-content;
  width: min-content;
  padding: 0;
  background: transparent;
  /* transform: translate3d(-10px, -10px, 0); */
}

.annotBtnWrapper__X.show {
  padding: 0.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  background: #fff;
}

.annotBtnWrapper__X button {
  display: inline-block;
  background: #646464 !important;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 5px solid white !important;
  outline: 1px solid #646464 !important;
}

.annotBtnWrapper__X .annotTitle__X {
  margin-left: 0.3rem;
  word-wrap: normal;
  word-break: keep-all;
  max-width: fit-content;
  width: fit-content;
  overflow: hidden;
  /* display: none; */
  flex: 0;
  transition: all 0.3s;
  z-index: 1000;
}

.annotBtnWrapper__X.show .annotTitle__X {
  /* display: block; */
  flex: 1;
  width: fit-content;
  z-index: 1010;
}
