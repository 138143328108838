.annotBtnWrapper__X {
  /* display: inline-block; */
  position: absolute;
  display: flex;
  align-items: center;
  cursor: pointer;
  /* border: 1px solid red; */
  height: min-content;
  width: min-content;
  padding: 0;
  background: transparent;
  /* transform: translate3d(-10px, -10px, 0); */
}
.annotBtnWrapper__X * {
  z-index: 10;
}

.annotBtnWrapper__X.show,
.annotBtnWrapper__X.clicked {
  padding: 0.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  background: #fff;
  z-index: 2147483647;
}
.annotBtnWrapper__X.clicked *,
.annotBtnWrapper__X.show * {
  z-index: 2147483647;
}
.annotBtnWrapper__X .annotTitle__X {
  margin-left: 0.3rem;
  word-wrap: normal;
  word-break: keep-all;
  max-width: fit-content;
  width: fit-content;
  overflow: hidden;
  /* display: none; */
  flex: 0;
  transition: all 0.3s;
  z-index: 1000;
}

.annotBtnWrapper__X.show .annotTitle__X {
  /* display: block; */
  flex: 1;
  width: fit-content;
  z-index: 1010;
}
.annotBtnWrapper__XX_TITLE {
  opacity: 0;
  width: 0;
  word-wrap: unset;
  word-break: keep-all;

  transition: all 0.3s;
}

.annotBtnWrapper__XX_TITLE.show {
  opacity: 1;
  min-width: max-content;
  width: max-content;
  padding: 0 0.5rem;
}
.annotBtnWrapper__XX_TITLE.clicked {
  opacity: 1;
  min-width: max-content;
  width: max-content;
  padding: 0 0.5rem;
}
