.instructionContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 3000;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-around; */
  transform-origin: center;
  transition: transform 0.5s;
  border: 1px solid #dddddd73;
  transform: scale(0);
  display: none;
}

.instructionContainer.show {
  transform: scale(1);
  display: flex;
}
