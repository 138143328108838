.__Actuality_menu_model_viewer {
  width: 0;
  height: 0;
  transition: width 0.5s, height 0.5s;
  overflow: hidden;
}

.__Actuality_menu_model_viewer.show {
  width: 220px;
  height: auto;
  transition: width 0.5s, height 0.5s;
}
