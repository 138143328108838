.HOTSPOT_DRAWER_ID {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: #fff;
  transform: translateX(200vw);
  transition: transform 0.5s 0.2s;
  box-shadow: -3px 0px 5px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.HOTSPOT_DRAWER_ID.show {
  transform: translateX(0);
}
