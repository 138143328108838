.mvDimension {
  opacity: 1;
  transition: all 300ms;
}

.mvDimension .hide {
  opacity: 0;
}
.hide {
  opacity: 0;
}
