/*
 * CKEditor 5 (v34.2.0) content styles.
 * Generated on Wed, 27 Jul 2022 09:05:51 GMT.
 * For more information, check out https://ckeditor.com/docs/ckeditor5/latest/installation/advanced/content-styles.html
 */

:root {
  --ck-color-base-active: hsl(208, 88%, 52%) !important;
  --ck-color-image-caption-background: hsl(0, 0%, 97%) !important;
  --ck-color-image-caption-text: hsl(0, 0%, 20%) !important;
  --ck-color-mention-background: hsla(341, 100%, 30%, 0.1) !important;
  --ck-color-mention-text: hsl(341, 100%, 30%) !important;
  --ck-color-table-caption-background: hsl(0, 0%, 97%) !important;
  --ck-color-table-caption-text: hsl(0, 0%, 20%) !important;
  --ck-color-table-column-resizer-hover: var(--ck-color-base-active) !important;
  --ck-highlight-marker-blue: hsl(201, 97%, 72%) !important;
  --ck-highlight-marker-green: hsl(120, 93%, 68%) !important;
  --ck-highlight-marker-pink: hsl(345, 96%, 73%) !important;
  --ck-highlight-marker-yellow: hsl(60, 97%, 73%) !important;
  --ck-highlight-pen-green: hsl(112, 100%, 27%) !important;
  --ck-highlight-pen-red: hsl(0, 85%, 49%) !important;
  --ck-image-style-spacing: 1.5em !important;
  --ck-inline-image-style-spacing: calc(
    var(--ck-image-style-spacing) / 2
  ) !important;
  --ck-table-column-resizer-position-offset: calc(
    var(--ck-table-column-resizer-width) * -0.5 - 0.5px
  ) !important;
  --ck-table-column-resizer-width: 7px !important;
  --ck-todo-list-checkmark-size: 16px !important;
  --ck-z-default: 1 !important;
}

/* ckeditor5-basic-styles/theme/code.css */
.ck-content code {
  background-color: hsla(0, 0%, 78%, 0.3) !important;
  padding: 0.15em !important;
  border-radius: 2px !important;
}
/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content blockquote {
  overflow: hidden !important;
  padding-right: 1.5em !important;
  padding-left: 1.5em !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  font-style: italic !important;
  border-left: solid 5px hsl(0, 0%, 80%) !important;
}
/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content[dir="rtl"] blockquote {
  border-left: 0 !important;
  border-right: solid 5px hsl(0, 0%, 80%) !important;
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-yellow {
  background-color: var(--ck-highlight-marker-yellow) !important;
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-green {
  background-color: var(--ck-highlight-marker-green) !important;
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-pink {
  background-color: var(--ck-highlight-marker-pink) !important;
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-blue {
  background-color: var(--ck-highlight-marker-blue) !important;
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-red {
  color: var(--ck-highlight-pen-red) !important;
  background-color: transparent !important;
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-green {
  color: var(--ck-highlight-pen-green) !important;
  background-color: transparent !important;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-tiny {
  font-size: 0.7em !important;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-small {
  font-size: 0.85em !important;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-big {
  font-size: 1.4em !important;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-huge {
  font-size: 1.8em !important;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image {
  display: table !important;
  clear: both !important;
  text-align: center !important;
  margin: 0.9em auto !important;
  min-width: 50px !important;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image img {
  display: block !important;
  margin: 0 auto !important;
  max-width: 100% !important;
  min-width: 100% !important;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image-inline {
  /*
     * Normally, the .image-inline would have "display: inline-block" and "img { width: 100% }" (to follow the wrapper while resizing). !important;
     * Unfortunately, together with "srcset", it gets automatically stretched up to the width of the editing root.
     * This strange behavior does not happen with inline-flex.
     */
  display: inline-flex !important;
  max-width: 100% !important;
  align-items: flex-start !important;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image-inline picture {
  display: flex !important;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image-inline picture,
.ck-content .image-inline img {
  flex-grow: 1 !important;
  flex-shrink: 1 !important;
  max-width: 100% !important;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left,
.ck-content .image-style-block-align-right {
  max-width: calc(100% - var(--ck-image-style-spacing)) !important;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left,
.ck-content .image-style-align-right {
  clear: none !important;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-side {
  float: right !important;
  margin-left: var(--ck-image-style-spacing) !important;
  max-width: 50% !important;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left {
  float: left !important;
  margin-right: var(--ck-image-style-spacing) !important;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-center {
  margin-left: auto !important;
  margin-right: auto !important;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-right {
  float: right !important;
  margin-left: var(--ck-image-style-spacing) !important;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-right {
  margin-right: 0 !important;
  margin-left: auto !important;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left {
  margin-left: 0 !important;
  margin-right: auto !important;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content p + .image-style-align-left,
.ck-content p + .image-style-align-right,
.ck-content p + .image-style-side {
  margin-top: 0 !important;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left,
.ck-content .image-inline.image-style-align-right {
  margin-top: var(--ck-inline-image-style-spacing) !important;
  margin-bottom: var(--ck-inline-image-style-spacing) !important;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left {
  margin-right: var(--ck-inline-image-style-spacing) !important;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-right {
  margin-left: var(--ck-inline-image-style-spacing) !important;
}
/* ckeditor5-image/theme/imagecaption.css */
.ck-content .image > figcaption {
  display: table-caption !important;
  caption-side: bottom !important;
  word-break: break-word !important;
  color: var(--ck-color-image-caption-text) !important;
  background-color: var(--ck-color-image-caption-background) !important;
  padding: 0.6em !important;
  font-size: 0.75em !important;
  outline-offset: -1px !important;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized {
  max-width: 100% !important;
  display: block !important;
  box-sizing: border-box !important;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized img {
  width: 100% !important;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized > figcaption {
  display: block !important;
}
/* ckeditor5-language/theme/language.css */
.ck-content span[lang] {
  font-style: italic !important;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list {
  list-style: none !important;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li {
  margin-bottom: 5px !important;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li .todo-list {
  margin-top: 5px !important;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input {
  -webkit-appearance: none !important;
  display: inline-block !important;
  position: relative !important;
  width: var(--ck-todo-list-checkmark-size) !important;
  height: var(--ck-todo-list-checkmark-size) !important;
  vertical-align: middle !important;
  border: 0 !important;
  left: -25px !important;
  margin-right: -15px !important;
  right: 0 !important;
  margin-left: 0 !important;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::before {
  display: block !important;
  position: absolute !important;
  box-sizing: border-box !important;
  content: "" !important;
  width: 100% !important;
  height: 100% !important;
  border: 1px solid hsl(0, 0%, 20%) !important;
  border-radius: 2px !important;
  transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background,
    250ms ease-in-out border !important;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::after {
  display: block !important;
  position: absolute !important;
  box-sizing: content-box !important;
  pointer-events: none !important;
  content: "" !important;
  left: calc(var(--ck-todo-list-checkmark-size) / 3) !important;
  top: calc(var(--ck-todo-list-checkmark-size) / 5.3) !important;
  width: calc(var(--ck-todo-list-checkmark-size) / 5.3) !important;
  height: calc(var(--ck-todo-list-checkmark-size) / 2.6) !important;
  border-style: solid !important;
  border-color: transparent !important;
  border-width: 0 calc(var(--ck-todo-list-checkmark-size) / 8)
    calc(var(--ck-todo-list-checkmark-size) / 8) 0 !important;
  transform: rotate(45deg) !important;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::before {
  background: hsl(126, 64%, 41%) !important;
  border-color: hsl(126, 64%, 41%) !important;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::after {
  border-color: hsl(0, 0%, 100%) !important;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label .todo-list__label__description {
  vertical-align: middle !important;
}
/* ckeditor5-media-embed/theme/mediaembed.css */
.ck-content .media {
  clear: both !important;
  margin: 0.9em 0 !important;
  display: block !important;
  min-width: 15em !important;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break {
  position: relative !important;
  clear: both !important;
  padding: 5px 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break::after {
  content: "" !important;
  position: absolute !important;
  border-bottom: 2px dashed hsl(0, 0%, 77%) !important;
  width: 100% !important;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break__label {
  position: relative !important;
  z-index: 1 !important;
  padding: 0.3em 0.6em !important;
  display: block !important;
  text-transform: uppercase !important;
  border: 1px solid hsl(0, 0%, 77%) !important;
  border-radius: 2px !important;
  font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif !important;
  font-size: 0.75em !important;
  font-weight: bold !important;
  color: hsl(0, 0%, 20%) !important;
  background: hsl(0, 0%, 100%) !important;
  box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15) !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table {
  margin: 0.9em auto !important;
  display: table !important;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table {
  border-collapse: collapse !important;
  border-spacing: 0 !important;
  width: 100% !important;
  height: 100% !important;
  border: 1px double hsl(0, 0%, 70%) !important;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table td,
.ck-content .table table th {
  min-width: 2em !important;
  padding: 0.4em !important;
  border: 1px solid hsl(0, 0%, 75%) !important;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table th {
  font-weight: bold !important;
  background: hsla(0, 0%, 0%, 5%) !important;
}
/* ckeditor5-table/theme/table.css */
.ck-content[dir="rtl"] .table th {
  text-align: right !important;
}
/* ckeditor5-table/theme/table.css */
.ck-content[dir="ltr"] .table th {
  text-align: left !important;
}
/* ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table table {
  overflow: hidden !important;
  table-layout: fixed !important;
}
/* ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table td,
.ck-content .table th {
  position: relative !important;
}
/* ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table .table-column-resizer {
  position: absolute !important;
  top: -999999px !important;
  bottom: -999999px !important;
  right: var(--ck-table-column-resizer-position-offset) !important;
  width: var(--ck-table-column-resizer-width) !important;
  cursor: col-resize !important;
  user-select: none !important;
  z-index: var(--ck-z-default) !important;
}
/* ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table[draggable] .table-column-resizer {
  display: none !important;
}
/* ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table .table-column-resizer:hover,
.ck-content .table .table-column-resizer__active {
  background-color: var(--ck-color-table-column-resizer-hover) !important;
  opacity: 0.25 !important;
}
/* ckeditor5-table/theme/tablecolumnresize.css */
.ck-content[dir="rtl"] .table .table-column-resizer {
  left: var(--ck-table-column-resizer-position-offset) !important;
  right: unset !important;
}
/* ckeditor5-table/theme/tablecolumnresize.css */
.ck-content.ck-read-only .table .table-column-resizer {
  display: none !important;
}
/* ckeditor5-table/theme/tablecaption.css */
.ck-content .table > figcaption {
  display: table-caption !important;
  caption-side: top !important;
  word-break: break-word !important;
  text-align: center !important;
  color: var(--ck-color-table-caption-text) !important;
  background-color: var(--ck-color-table-caption-background) !important;
  padding: 0.6em !important;
  font-size: 0.75em !important;
  outline-offset: -1px !important;
}
/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre {
  padding: 1em !important;
  color: hsl(0, 0%, 20.8%) !important;
  background: hsla(0, 0%, 78%, 0.3) !important;
  border: 1px solid hsl(0, 0%, 77%) !important;
  border-radius: 2px !important;
  text-align: left !important;
  direction: ltr !important;
  tab-size: 4 !important;
  white-space: pre-wrap !important;
  font-style: normal !important;
  min-width: 200px !important;
}
/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre code {
  background: unset !important;
  padding: 0 !important;
  border-radius: 0 !important;
}
/* ckeditor5-horizontal-line/theme/horizontalline.css */
.ck-content hr {
  margin: 15px 0 !important;
  height: 4px !important;
  background: hsl(0, 0%, 87%) !important;
  border: 0 !important;
}
/* ckeditor5-mention/theme/mention.css */
.ck-content .mention {
  background: var(--ck-color-mention-background) !important;
  color: var(--ck-color-mention-text) !important;
}
@media print {
  /* ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break {
    padding: 0 !important;
  }
  /* ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break::after {
    display: none !important;
  }
}
