.SDM {
  $zIndex: 1000;

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: Inter;
  }
  button {
    cursor: pointer;
    border: none;
  }
  .wrapper {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: grid;
    place-items: center;
  }

  .modelContainer {
    background-color: white;
    width: 100vw;
    height: 100vh;
    border-radius: 6px;
    border: 1px solid #dddddd73;
    padding: 0.5rem 0 0 0;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -2px rgba(0, 0, 0, 0.1);
    position: relative;
    border: 0px solid blue;
    overflow: hidden;
  }

  model-viewer {
    width: 100%;
    height: 100%;
    z-index: $zIndex;
  }

  model-viewer * {
    z-index: $zIndex !important;
  }

  model-viewer #default-ar-button {
    display: none !important;
  }

  model-viewer * {
    z-index: $zIndex;
  }

  model-viewer .slot.ar-button {
    z-index: $zIndex;
  }

  model-viewer::part(default-ar-button) {
    display: none !important;
  }

  .bgWhiteTopMost {
    background-color: #fff;
    z-index: $zIndex + 1000;
  }

  .mvLevel {
    z-index: $zIndex;
    background: transparent !important ;
  }
}

.arNotSupportedRef > *,
.arNotSupportedRef * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.arNotSupportedRef {
  position: fixed;
  // border: 2px solid aqua;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  max-width: 100vw;
  width: 100%;
  max-height: 100vh;
  height: 100%;

  overflow: hidden;

  display: grid;
  place-items: center;
  z-index: 500000001;
  background: transparent;
  transform: scale(0);
  // background-color: rgba(255, 255, 255, 0.3);
}

.arNotSupportedRefContent {
  border-radius: 16px;
  max-width: 90vw;
  width: 90vw;
  z-index: 5000005552;
  background-color: #fff;
  // padding: 2rem;
  // border: 2px solid red;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  // padding-top: 0;
  // padding-right: 0;
  padding: 2rem 1rem;
  border: 0px solid red;
}

.arNotSupportedRef.show {
  transform: scale(1);
}
