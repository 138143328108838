.__NO__SCROLLBAR__ *::-webkit-scrollbar {
  display: none !important;
  -ms-overflow-style: none !important;
  width: 0 !important;
  background-color: transparent !important;
  /* border: 1px solid red !important; */
}
.__NO__SCROLLBAR__ *::-webkit-scrollbar-thumb {
  background: white !important;
}

.__NO__SCROLLBAR__ *::-webkit-scrollbar-track {
  background: white !important;
}
.capitalise {
  text-transform: capitalize !important;
}
model-viewer .hide {
  display: none !important;
}
/* This keeps child nodes hidden while the element loads */
mdoel-viewer :not(:defined) > * {
  display: none !important;
}
.__TSE_DIALOG,
.__TSE_DIALOG * {
  box-sizing: border-box !important;
  margin: 0;
  padding: 0;
}
