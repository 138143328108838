.__SHARE_DESKTOP_UI,
.__SHARE_DESKTOP_UI * {
  box-sizing: border-box !important;
  margin: 0;
  padding: 0;
}

/* .__SHARE_DESKTOP_UI *::-webkit-scrollbar-thumb {
  background: white !important;
}
.__SHARE_DESKTOP_UI *::-webkit-scrollbar-track {
  background: white !important;
}
.__SHARE_DESKTOP_UI * ::-webkit-scrollbar {
  width: 0 !important;
  display: none !important;
} */

.FULL_STRETCH {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.truncateEllipse {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.__SHARE_DESKTOP_UI p {
  margin: 0;
  padding: 0;
}
.__NO_SCROLLBAR {
}
