* {
  font-family: "Inter", sans-serif !important;
}
.colseModelbtn {
  position: absolute;
  top: 13px;
  right: 17px;
}
.hide {
  background-color: #ffffffdd;
  border-radius: 16px;
  padding: 16px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  transition: opacity 0.3s;
  opacity: 0;
  visibility: hidden;
  transition: visibility 2s, opacity 1s 1s;
}

.modalWrap {
  padding: 10px;
}
.headerTop {
  display: inline-flex;
  justify-content: space-between;
  flex-direction: row;
}
.closeIcon {
  width: 35px;
  height: 35px;
  position: absolute;
  right: 12%;
  top: 13%;
}
.subtitle {
  margin-top: -31px;
  margin-left: 19px;
  font-size: 12px;
}
.selectedCheck {
  align-items: center;
  bottom: 0;
  color: #fff;
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 0.3;
  position: absolute;
  right: 0;
  top: 0;
}
.paletteBoxModal {
  border-radius: 5px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  height: 50px;
  margin: 6px;
  position: relative;
  transition: 0.3s;
  width: 50px;
}
.imageChair {
  width: 245px;
  height: 350px;
}
.verticalScrollBar {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 0px 10px 10px 0px;
  width: 81px;
  height: 310px;
  margin-left: -10px;
  padding-left: 19px;
  overflow: hidden;
  overflow-y: scroll;
  margin-top: 2%;
  position: absolute;
  left: 11%;
}
.slide-in {
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
}

.slide-out {
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards;
}
@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  100% {
    -webkit-transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}

.arrowWrap {
  width: 35.25px;
  height: 35.25px;
  border-radius: 20px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 47%;
  left: 18%;
  justify-content: center;
  align-items: center;
}
.buy_now_Wrap {
  position: absolute;
  right: 10%;
  background: #fff;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 15px 0px 0px 15px;
  top: 47%;
  padding: 10px;
  width: 18%;
}
.arrowWrapRight {
  width: 35.25px;
  height: 35.25px;
  border-radius: 20px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 44%;
  right: 29%;
  justify-content: center;
  align-items: center;
}
.arrowRight {
  width: 11px;
  height: 15px;
  text-align: center;
  padding-top: 9px;
  align-self: center;
  padding-left: 11px;
  cursor: pointer;
}
.arrowLeft {
  width: 11px;
  height: 15px;
  text-align: center;
  padding-top: 9px;
  align-self: center;
  padding-left: 11px;
  cursor: pointer;
}
.imgWrap {
  justify-content: center;
  align-items: center;
  text-align: center;
}
.comapnyLogo {
  width: 95px;
  height: 95px;
  overflow: hidden;
  border-radius: 15px;
  // background-color: #ddd;
  padding: 3px;
}
.Product-title {
  font-weight: 500 !important;
  color: #000;
  font-size: 20px !important;
  line-height: 56px !important;
  font-family: "Inter" !important;
  padding-left: 20px;
}
.prd1 {
  font-weight: 500;
  font-size: 17px;
  // line-height: 56px;
  color: #000000;
  // padding-left: 20px;
  letter-spacing: -1px;
  // position: relative;
  // bottom: 50px;
  padding: 12px;
  margin: 0;
  margin-bottom: 12px;
}
.prd {
  font-weight: 500;
  font-size: 17px;
  line-height: 56px;
  color: #000000;
  padding-left: 13px;
  letter-spacing: -1px;
}
.productName {
  // background-color: #fff;
  // width: 255px;
  // height: 88px;
  // border-radius: 15px;
  // color: #000;
  // box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  // position: relative;
  // top: 5px;
  background-color: #fff;
  width: 100%;
  // height: 80px;
  border-radius: 15px;
  color: #000;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  position: relative;
  top: 0px;
}
.callToAction {
  background-color: #fff;
  width: 230px;
  height: 80px;
  border-radius: 15px;
  color: #000;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  position: relative;
  top: -13px;
}
.variants {
  background-color: #fff;
  width: 255px;
  height: auto;
  border-radius: 15px;
  color: #000;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  position: relative;
  top: 0px;
}
.singleComponent::-webkit-scrollbar {
  width: 0px;
  display: none !important;
}
.singleComponent::-webkit-scrollbar-track {
  background: #f1f1f1;
  display: none !important;
}
.singleComponent::-webkit-scrollbar-thumb {
  background: #888;
  display: none !important;
}

.singleVariant::-webkit-scrollbar {
  width: 0px;
  display: none !important;
}
.singleVariant::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.singleVariant::-webkit-scrollbar-thumb {
  background: #888;
}

.framesWrapper::-webkit-scrollbar {
  width: 0px;
  display: none !important;
}
.framesWrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
  display: none !important;
}
.framesWrapper::-webkit-scrollbar-thumb {
  background: #888;
  display: none !important;
}

.variantListing::-webkit-scrollbar {
  width: 0px;
  display: none !important;
}
.variantListing::-webkit-scrollbar-track {
  background: #f1f1f1;
  display: none !important;
}
.variantListing::-webkit-scrollbar-thumb {
  background: #888;
  display: none !important;
}
.closeToggle {
  position: absolute;
  right: 10px;
  top: 12px;
}
.sideBar {
  position: fixed;
  // height: 100vh;
  top: 20px;
  // background-color: #FFFFFF;
  z-index: 21474836479;
  padding: 0 16px;
  max-width: 315px;
  transition: all 0.3s ease;
  overflow: scroll;
  right: 0;
  width: 230px;
  color: #fff;
  // right: 10px;
}
.topContent {
  background-color: #f1f2f3;
  width: 250px;
  height: 90px;
  position: relative;
  right: 34px;
  bottom: 10px;
  color: #000;
}
.helloCont {
  position: relative;
  top: 35px;
  font-size: 20px;
  font-family: auto;
}
.home {
  font-size: 18px;
  font-family: auto;
  padding: 16px;
  text-decoration: none;
  color: #fff;
  position: relative;
  right: 70px;
  top: 15px;
}
.menusArea {
  text-align: justify;
  position: relative;
  left: 50px;
}

.toggleDiv {
  background-color: #ddd;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
.modelCompanyLogo {
  width: 112px;
  height: 112px;
  overflow: hidden;
  border-radius: 15px;
  // background-color: #ddd;
  padding: 3px;
  position: absolute;
  top: 26px;
  left: 24px;
}
.modelCompanyLogo img {
  width: 112px;
  height: 112px;
}
.menuHamburger {
  width: 33px;
  height: 45px;
  position: absolute;
  top: 26px;
  right: 21px;
  z-index: 2147483648;
}
.getInTouchBtn {
  background-color: #bdbdbd;
  color: #fff;
  box-shadow: none;
  text-transform: none;
  font-weight: 700;
  padding: 8px 30px;
  margin-left: 19px;
  margin-top: 50px;
  &:hover {
    transition: 0.3s;
    background-color: rgba(146, 180, 211, 0.8);
    color: #fff;

    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
}
.bookDm {
  background-color: #bdbdbd;
  border-radius: 8px;
  margin-top: 0px;
  width: 24%;
}
.bookDmResp {
  background-color: #bdbdbd;
  border-radius: 8px;
  margin-top: 0px;
  width: 27%;
}
.progressPara {
  font-size: 18px;
  text-align: center;
  font-weight: 400;
  line-height: 26px;
  font-family: Inter;
  font-style: normal;
  color: #000000;
}
.progressParaResp {
  font-size: 13px;
  text-align: center;
  font-weight: 400;
  line-height: 26px;
  font-family: Inter;
  font-style: normal;
  color: #000000;
}
.CircularProgressbar {
  width: 35%;
  vertical-align: middle;
}
.ReactCrop__image {
  display: block;
  width: 800px;
  height: 550px;
  -ms-touch-action: none;
  touch-action: none;
}
.serviceBtnLanding {
  position: relative;
  width: 21%;
  height: 55px;
  // left: 34%;
  top: 10px;
  font-weight: bold;
  font-size: 18px;
  // text-transform: none;
  transition: 0.3s;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.2px;

  background: #bdbdbd;
  border-radius: 7px;
  &:hover {
    .serviceBtnLanding {
      transition: 0.3s;
      background-color: rgba(146, 180, 211, 0.8);
      color: #fff;
    }
  }
}
.serviceBtnLandingResp {
  position: relative;
  width: 27%;
  height: 55px;
  // left: 34%;
  top: 10px;
  font-weight: bold;
  font-size: 18px;
  // text-transform: none;
  transition: 0.3s;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.2px;

  background: #bdbdbd;
  border-radius: 7px;
  &:hover {
    .serviceBtnLanding {
      transition: 0.3s;
      background-color: rgba(146, 180, 211, 0.8);
      color: #fff;
    }
  }
}

.confirmationModalPublish {
  text-align: center;
  padding: 34px;
  justify-content: center;
  align-items: center;
  padding-top: 62px;
  .DeleteBtnPublish {
    background-color: rgba(146, 180, 211, 0.8);
    color: #fff;
    padding: 3px 10px;
    border-radius: 6px;
    margin: 10px;
    width: 55%;
    height: 50px;
    text-transform: capitalize;
    &:hover {
      transition: 0.3s;
      background-color: rgba(146, 180, 211, 0.8);
      color: #fff;

      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.06);
    }
  }
}
.published {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-top: -18px;
  margin-left: 17px;
  padding-left: 17px;
  text-align: left;
  letter-spacing: 0.25px;
  color: #999999;
}
.infoItemPlan {
  margin: 10px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: #2d3436;
}
.infoItemPlanDetail {
  margin: 20px 0;
  font-style: normal;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: #2d3436;
  font-weight: 500;
  padding-bottom: 10px;
}
.detailBack {
  background: #ffffff;
  color: #828282;
  font-size: 25px;
  padding: 10px;
  width: 100%;
  border-radius: 12px;
  box-shadow: 2px 3px 13px 2px rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  // &:hover {
  //   transition: 0.3s;
  //   background-color: rgba(146, 180, 211, 0.8);
  //   color: #fff;
  // }
}
.title {
  color: #828282;
  font-weight: 400;
  font-size: 25px;
  font-weight: bold;
}
.amount {
  color: #828282;
  font-weight: 400;
  font-size: 22px;
}
.date {
  color: #828282;
  font-weight: 400;
  font-size: 22px;
}
.saveBtnCrop {
  background: #bdbdbd;
  text-transform: capitalize;
  box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  border-radius: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 36px;
  width: 45%;
  padding: 5px 80px;
  border: none;
  margin-top: -85px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  color: #ffffff;
  cursor: pointer;
  text-shadow: 0px 2px 4px rgba(38, 50, 56, 0.16),
    0px 4px 8px rgba(38, 50, 56, 0.08);
  &:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    transition: 0.3s;
    background-color: rgba(146, 180, 211, 0.8);
    color: #fff;
  }
}
.cancelBtnCrop {
  background: #bdbdbd;
  text-transform: capitalize;
  box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  border-radius: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 36px;
  width: 45%;
  padding: 5px 80px;
  border: none;
  margin-top: -45px;
  margin-left: 255px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  color: #ffffff;
  cursor: pointer;
  text-shadow: 0px 2px 4px rgba(38, 50, 56, 0.16),
    0px 4px 8px rgba(38, 50, 56, 0.08);
  &:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    transition: 0.3s;
    background-color: rgba(146, 180, 211, 0.8);
    color: #fff;
  }
}
.plans {
  font-size: 60px;
  line-height: 72.61px;
  text-align: center;
  font-weight: 600;
}
.plansNew {
  font-size: 70px;
  line-height: 104px;
  letter-spacing: -3px;
  text-align: center;
  font-weight: 300;
  position: relative;
}
.textRight {
  width: 40%;
  flex: 1;
  margin-left: 92px;
}
.textRightResp {
  width: 40%;
  flex: 1;
}
.leftImg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -40%;
}
.mainClass {
  width: 20%;
  position: relative;
  flex: 1;
  left: -20%;
}
.mainClassResp {
  width: 40%;
  position: relative;
  flex: 0.6;
  left: -11%;
}
.crossImg {
  position: absolute;
  left: 4px;
  top: 146px;
}
.crossImgResp {
  position: absolute;
  left: 4px;
  top: 150px;
}
.crossImg2 {
  position: absolute;
  left: 4px;
  top: 172px;
}
.crossImg2Resp {
  position: absolute;
  left: 4px;
  top: 177px;
}
.crossImg3 {
  position: absolute;
  left: 4px;
  top: 199px;
}
.crossImg3Resp {
  position: absolute;
  left: 4px;
  top: 203px;
}
.mainClassSecondResp {
  width: 40%;
  position: relative;
  flex: 0.6;
  right: -51%;
}
.leftImgResp {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -42%;
}
.contentParagraph {
  text-align: center;
  font-family: Inter;
  font-weight: 400;
  font-size: 18px;
  position: relative;
  line-height: 32px;
}
.blogName {
  position: relative;
  right: 80px;
  bottom: 20px;
}
.blogNameResp {
  position: relative;
  right: 50px;
  bottom: 20px;
}
.paraNew1013 {
  text-align: center;
  font-size: 18px;
  position: relative;
  transform: translate(-50px, -50px);
  left: 17%;
  top: 40px;
  line-height: 32px;
  width: 715px;
}
.subTitlePlans {
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.2px;
  position: relative;
  bottom: 30px;
}
.annualPlan {
  padding-right: 10px;
  line-height: 30px;
  font-size: 18px;
  font-weight: 500;
  font-family: "Inter";
}
.monthlyPlan {
  padding-left: 10px;
  line-height: 30px;
  font-size: 18px;
  font-weight: 500;
  font-family: "Inter";
}
.toggleArea {
  text-align: center;
  position: relative;
  transform: translate(-50%, -50%);
  left: 37%;
  top: -5px;
}

.toggleArea1100 {
  text-align: center;
  position: relative;
  transform: translate(-50%, -50%);
  left: 14%;
  top: -5px;
}

.helpWe {
  text-align: center;
  font-size: 70px;
  font-weight: 300;
  line-height: 104px;
  letter-spacing: -3px;
}
.paraNew {
  text-align: center;
  font-size: 18px;
  position: relative;
  transform: translate(-50px, -50px);
  left: 20%;
  top: 40px;
  line-height: 32px;
  width: 840px;
}
.para {
  text-align: center;
  font-size: 15px;
  // width: 770px;
  position: relative;
  transform: translate(-50px, -50px);
  left: 15%;
  line-height: 32px;
}
.para1536 {
  text-align: center;
  font-size: 18px;
  width: 840px;
  position: relative;
  transform: translate(-50px, -50px);
  left: 20%;
  line-height: 32px;
}

.para1046 {
  text-align: center;
  font-size: 18px;
  width: 840px;
  position: relative;
  transform: translate(-50px, -50px);
  left: 20%;
  line-height: 32px;
}

.para1560 {
  text-align: center;
  font-size: 18px;
  width: 840px;
  position: relative;
  transform: translate(-50px, -50px);
  left: 21%;
  line-height: 32px;
}

.paraBlog {
  text-align: center;
  line-height: 32px;
  font-size: 18px;
  width: 840px;
  position: relative;
  transform: translate(-50px, -50px);
  left: 19%;
  top: 50px;
}

.paraBlog1100 {
  text-align: center;
  line-height: 32px;
  font-size: 11px;
  width: 840px;
  position: relative;
  transform: translate(-50px, -50px);
  left: 10%;
  top: 35px;
}

.paraBlogDetailDesc {
  text-align: justify;
  line-height: 32px;
  font-size: 18px;
  width: 100%;
  position: relative;
  transform: translate(-50px, -50px);
  left: 4%;
  padding-top: 7%;
}
.paraBlogDetailDesc1100 {
  text-align: justify;
  line-height: 32px;
  font-size: 11px;
  width: 100%;
  position: relative;
  transform: translate(-50px, -50px);
  left: 6%;
  padding-top: 7%;
}

.paraBlogDescription {
  text-align: center;
  line-height: 32px;
  font-size: 18px;
  width: 100%;
  position: relative;
  transform: translate(-50px, -50px);
  left: 4%;
}
.slide-In {
  animation: slide-In 0.6s linear;
  transition: 6s linear ease-in;
}
@keyframes slide-In {
  0% {
    transform: translateX(0px);
    opacity: 0;
  }

  100% {
    transform: translateX(5px);
    opacity: 1;
  }
}
.slideOut {
  animation: slideOut 0.2s linear;
  transition: 1s linear ease-in;
}
@keyframes slideOut {
  0% {
    transform: translateX(5px);
    opacity: 0;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
.slideFromRight {
  animation: fromRight 0.6s linear;
  transition: 6s linear ease-in;
}
@keyframes fromRight {
  0% {
    transform: translateX(10px);
    opacity: 0;
    transition: 0.6s;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
    transition: 0.6s;
  }
}
.slideFromLeft {
  animation: fromLeft 0.6s linear;
  transition: 6s linear ease-in;
}
@keyframes fromLeft {
  0% {
    transform: translateX(0px);
    opacity: 0;
    transition: 0.6s;
  }

  100% {
    transform: translateX(10px);
    opacity: 1;
    transition: 0.6s;
  }
}

@media screen and (max-width: 767px) {
  .web {
    display: none !important;
  }
  .responsive {
    display: block !important;
  }
  .mobileResponsiveViewer {
    position: relative;
    overflow-x: hidden;
    .view_space_btn {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      /* identical to box height, or 156% */
      display: flex;
      align-items: flex-end;
      letter-spacing: -1px;
      color: #000000;
      margin-left: 12px;
      // box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
      // &:hover {
      //   box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
      // }
    }
    .header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 9999;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 16px;
      box-sizing: border-box;

      .brand {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        color: #2d3436;
        align-self: start;
        position: relative;
        z-index: 9999;
        margin-bottom: 0;
        position: relative;
        top: 0;
        left: 0;
      }
      .proName {
        background: #ffffff;
        box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        min-width: 150px;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        padding: 6px 16px;
        /* or 311% */
        display: flex;
        align-items: flex-end;
        letter-spacing: -1px;
        margin-top: -4px;
        color: #000000;
      }
      .buyNow {
        background: #ffffff;
        box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        padding: 6px 16px;
        /* or 311% */
        display: flex;
        align-items: flex-end;
        letter-spacing: -1px;
        cursor: pointer;
        color: #000000;
        text-decoration: none;
        padding-top: 17px;
      }
      .staticModal {
        background: #ffffff;
        box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        padding: 6px 16px;
        /* or 311% */
        letter-spacing: -1px;
        cursor: pointer;
        color: #000000;
        margin-left: 3px;
      }
      .name {
        font-size: 12px;
        margin-bottom: 3px;
        font-weight: bold;
        letter-spacing: -1px;
      }
      .subTitleInstatic {
        font-size: 8px;
        letter-spacing: -1px;
      }
      .shareBtn {
        background: #ffffff;
        box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        width: 40px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 8px 8px 8px 0;
      }
      .shareBtnResp {
        background: #ffffff;
        box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        width: 40px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 8px 8px 8px 0;
        position: relative;
        top: 200px;
        left: 240px;
      }
    }
    .variant__wrapper .variantControls,
    .variant__wrapper,
    .variantControls {
      position: absolute;
      bottom: 40px;
      left: 0;
      overflow-x: auto;
      // box-sizing: border-box;
      padding: 0 16px;
      .framesWrapper {
        // display: flex;
        // flex-direction: row;
        align-items: center;
        max-height: 90px;
        overflow-y: scroll;
        .variantListing {
          display: flex;
          flex-direction: row;
          min-width: 120px;
        }
        .separator {
          height: 75px;
          position: relative;
          top: 0;
          margin: 0 32px;
          border: 0.5px solid #727272;
        }
      }
    }

    .poweredBy {
      position: absolute;
      bottom: 6px;
      left: 0;
      // overflow-x: scroll;
      box-sizing: border-box;
      padding: 6px 0px 6px 0px;
      // padding-bottom: 0;
      text-align: center;
      font-family: "Inter";
      .framesWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        .variantListing {
          display: flex;
          flex-direction: row;
          min-width: 120px;
        }
        .separator {
          height: 75px;
          position: relative;
          top: 0;
          margin: 0 32px;
          border: 0.5px solid #727272;
        }
      }
    }
  }
  .responsive .para {
    text-align: center;
    font-size: 12px;
    width: 770px;
    position: relative;
    transform: translate(-50px, -50px);
    left: 16%;
  }
  .shareModalWrap {
    width: 99vw !important;
    height: 80vh;
    background-color: #fff !important;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -2px rgba(0, 0, 0, 0.1) !important;
    border: 1px solid #dddddd73;
    border-radius: 6px;
    .logo {
      width: 57px !important;
    }
    .img {
      border-radius: 8px !important;
    }
    .sideBar {
      position: fixed;
      top: 78px;
      z-index: 21474836479;
      padding: 0 16px;
      max-width: 315px;
      transition: all 0.3s ease;
      overflow: scroll;
      right: 15px;
      width: 230px;
      color: #fff;
    }
  }
}
.weHelpu {
  text-align: center;
  font-size: 70px;
  font-weight: 300;
  line-height: 104px;
  font-family: Inter;
  font-style: normal;
}
.weHelpuResp {
  text-align: center;
  font-size: 60px;
  font-weight: 300;
  line-height: 104px;
  font-family: Inter;
  font-style: normal;
  letter-spacing: -3px;
  padding-left: 7%;
}
.weHelpuResp360 {
  text-align: center;
  font-size: 60px;
  font-weight: 300;
  line-height: 104px;
  font-family: Inter;
  font-style: normal;
  letter-spacing: -3px;
  padding-left: 7%;
}
.enhance {
  font-weight: 800;
}
.paraGraph {
  width: 844px;
  font-family: Inter;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  font-style: normal;
  position: relative;
  transform: translate(-50%, -50%);
  left: 50%;
}

.paraGraphResp {
  font-family: Inter;
  font-weight: 400;
  font-size: 15px;
  line-height: 32px;
  text-align: center;
  font-style: normal;
  position: relative;
  transform: translate(-50%, -50%);
  left: 53%;
}

.paraGraphResp1093 {
  font-family: Inter;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  font-style: normal;
  position: relative;
  transform: translate(-50%, -50%);
  left: 53%;
  top: 25px;
}

.leftCard {
  background: #396996;
  border-radius: 16px;
  position: absolute;
  width: 32%;
  height: 100%;
  left: -35px;
}

@font-face {
  font-family: "Inter";
  font-weight: 900;
  src: local("Inter"), url(./Assets/fonts/Inter-Black.ttf) format("opentype");
}

@font-face {
  font-family: "Inter";
  font-weight: 800;
  src: local("Inter"),
    url(./Assets/fonts/Inter-ExtraBold.ttf) format("opentype");
}

@font-face {
  font-family: "Inter";
  font-weight: 700;
  src: local("Inter"), url(./Assets/fonts/Inter-Bold.ttf) format("opentype");
}
@font-face {
  font-family: "Inter";
  font-weight: 600;
  src: local("Inter"), url(./Assets/fonts/Inter-SemiBold.ttf) format("opentype");
}
@font-face {
  font-family: "Inter";
  font-weight: 500;
  src: local("Inter"), url(./Assets/fonts/Inter-Medium.ttf) format("opentype");
}
@font-face {
  font-family: "Inter";
  font-weight: 400;
  src: local("Inter"), url(./Assets/fonts/Inter-Regular.ttf) format("opentype");
}
@font-face {
  font-family: "Inter";
  font-weight: 300;
  src: local("Inter"), url(./Assets/fonts/Inter-Light.ttf) format("opentype");
}
@font-face {
  font-family: "Inter";
  font-weight: 200;
  src: local("Inter"),
    url(./Assets/fonts/Inter-ExtraLight.ttf) format("opentype");
}
@font-face {
  font-family: "Inter";
  font-weight: 100;
  src: local("Inter"), url(./Assets/fonts/Inter-Thin.ttf) format("opentype");
}
$bgTheme: #fff;
$txtTheme: #2d3436;
$mainBg: #bdbdbd;

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "Inter", sans-serif !important;
  color: $txtTheme;
}
body {
  overflow-x: hidden;
}
.element::-webkit-scrollbar {
  width: 0 !important;
}
.element::-webkit-scrollbar {
  display: none;
}
#root {
  .bottomSpacing {
    margin-bottom: 16px;
  }
  .bottomMaxSpacing {
    margin-bottom: 100px;
  }
  .verticalSpacing {
    margin: 64px 0;
  }
  .bgTheme {
    background-color: $bgTheme !important;
    color: $txtTheme;
  }
  .listItems {
    list-style: none;
    display: inline-flex;
    justify-content: end;
    width: 67%;
  }
  .listItemsResp {
    list-style: none;
    display: inline-flex;
    justify-content: end;
    width: 44%;
  }
  .bolder {
    font-weight: bolder;
  }
  .linkStyle {
    text-decoration: none;
  }
  body::-webkit-scrollbar {
    width: 2px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ddd;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .DemoText {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    // margin: 0px 13px;
    line-height: 32px;
    /* or 133% */
    padding: 0px 10px;
    display: flex;
    align-items: center;
    text-align: center;
    cursor: pointer;
    // width: 100px;

    color: #828282;
  }
  .Lottiewrapper {
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    .typo {
      font-family: Inter;
      font-style: normal;
      color: #808080;
    }
  }
  .web {
    display: block;
  }
  .responsive {
    display: none;
  }
  .bold {
    font-weight: 600;
  }
  .noBorder {
    border: none;
  }
  .expandIcon {
    position: absolute;
    left: 2%;
    top: 31%;
  }
  .rounded {
    border-radius: 15px;
  }
  .roundedPlansPricing {
    border-radius: 8px;
  }
  .containerSpacing {
    padding: 0 100px;
  }
  .containerSpacingdashBoard {
    padding: 0 5px;
  }
  .flexChildCenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .themeFontColor {
    color: $txtTheme;
  }
  .lineHeight {
    line-height: 32px;
  }
  .txtShadow {
    text-shadow: 0px 2px 4px rgba(38, 50, 56, 0.16),
      0px 4px 8px rgba(38, 50, 56, 0.08);
  }
  .txtWhiteShadow {
    text-shadow: 0px 4px 0px #ffffff;
  }

  .themeBtnSuccess {
    background-color: $mainBg;
    color: #fff;
    box-shadow: none;
    text-transform: none;
    font-weight: 700;
    padding: 15px 35px;
    margin-left: 19px;
    margin-top: 30px;
    border: none;
    font-size: 15px;
    cursor: pointer;
    &:hover {
      transition: 0.3s;
      background-color: rgba(146, 180, 211, 0.8);
      color: #fff;

      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.06);
    }
  }

  .themeBtn {
    background-color: $mainBg;
    color: #fff;
    box-shadow: none;
    text-transform: none;
    font-weight: 700;
    padding: 8px 30px;
    margin-left: 19px;
    width: 220px;
    &:hover {
      transition: 0.3s;
      background-color: rgba(146, 180, 211, 0.8);
      color: #fff;

      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.06);
    }
  }

  .getInTouchHero {
    background-color: $mainBg;
    color: #fff;
    box-shadow: none;
    text-transform: none;
    font-weight: 700;
    padding: 8px 30px;
    margin-left: 19px;
    margin-top: 80px;
    margin-bottom: 30px;
    font-size: 24px;
    width: 220px;
    cursor: pointer;
    &:hover {
      transition: 0.3s;
      background-color: rgba(146, 180, 211, 0.8);
      color: #fff;

      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.06);
    }
  }

  .getInTouchLanding {
    background-color: $mainBg;
    color: #fff;
    box-shadow: none;
    text-transform: none;
    font-weight: 700;
    padding: 8px 30px;
    margin-left: 19px;
    margin-top: 80px;
    // margin-bottom: 30px;
    font-size: 24px;
    cursor: pointer;
    width: 220px;
    &:hover {
      transition: 0.3s;
      background-color: rgba(146, 180, 211, 0.8);
      color: #fff;

      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.06);
    }
  }

  .bookDemoLanding {
    background-color: $mainBg;
    color: #fff;
    box-shadow: none;
    text-transform: none;
    font-weight: 700;
    padding: 8px 30px;
    margin-left: 19px;
    margin-top: 0px;
    // margin-bottom: 30px;
    font-size: 24px;
    cursor: pointer;
    width: 220px;
    &:hover {
      transition: 0.3s;
      background-color: rgba(146, 180, 211, 0.8);
      color: #fff;

      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.06);
    }
  }

  .sendBtnLanding {
    background-color: #bdbdbd;
    color: #fff;
    box-shadow: none;
    text-transform: none;
    font-weight: 700;
    padding: 14px 30px;
    margin-left: 0px;
    margin-top: 12px;
    font-size: 24px;
    cursor: pointer;
    width: 165px;
    &:hover {
      transition: 0.3s;
      background-color: rgba(146, 180, 211, 0.8);
      color: #fff;

      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.06);
    }
  }

  .themeBtnSelected {
    background-color: #0d0d0d;
    color: #fff;
    box-shadow: none;
    text-transform: none;
    font-weight: 700;
    padding: 8px 30px;
    margin-left: 19px;
    &:hover {
      transition: 0.3s;
      background-color: rgba(146, 180, 211, 0.8);
      color: #fff;

      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.06);
    }
  }
  .flexWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .themeGrayBtn {
    background-color: #e3e3e3;
    color: #8c8c8c;
    box-shadow: none;
    text-transform: none;
    padding: 4px 16px;
    transition: 0.3s;
  }
  .compactTxt {
    letter-spacing: -3px;
  }

  .hero {
    padding-top: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow-x: hidden;
    .rect_ele {
      position: fixed;
      width: 100%;
      height: 700px;
      left: 46%;
      top: -17.5%;
      background: rgba(146, 180, 211, 1);
      border-radius: 99px;
      transform: rotate(-45deg);
      z-index: -2;
      opacity: 0;
    }
    .heroTitle {
      font-size: 5.5rem;
      margin-bottom: 16px;
    }
    .tryButton {
      background-color: rgba(146, 180, 211, 0.8);
      color: #fff;
      font-size: 28px;
      padding: 12px 32px;
      align-self: center;
      letter-spacing: -0.5px;
      text-transform: none;
      border-radius: 16px;
      margin: 80px auto;
    }
  }

  .AR {
    position: relative;
    margin: 0 8px;
    &::before {
      content: "";
      background: rgba(146, 180, 211, 0.8);
      border-radius: 30px;
      width: 150px;
      height: 40px;
      position: absolute;
      top: 60%;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
    }
  }

  .navbar {
    border-bottom: 1px solid #ddd;
    padding-top: 12px;
    padding-bottom: 12px;
    button {
      font-size: 24px;
    }
  }
  .banner {
    margin: 32px 0 100px 0;
  }
  .text-center {
    text-align: center;
  }
  .view_in_space_btn {
    font-size: 28px;
    padding: 8px 32px;
    width: 88%;
    align-self: center;
    letter-spacing: -0.5px;
    border-radius: 16px;
    margin: 0 auto;
    font-weight: 500;
    span::first-letter {
      text-transform: none;
    }
  }
  .seeMoreBtn {
    line-height: 48px;
    width: 361px;
    border-radius: 15px;
    font-size: 28px;
    font-weight: 500;
    margin-top: 100px;
    margin-bottom: 0;
  }

  .pricingCard1100 {
    // height: 100%;
    // background: #ffffff;
    // box-shadow: 1px 2px 4px 4px rgba(0, 0, 0, 0.25);
    width: 230px;
    height: 730px;

    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: 0.3s;
    .beginner {
      font-size: 18px;
      font-weight: 400;
      line-height: 21.78px;
      text-align: center;
      font-family: Inter;
    }
    .price {
      font-size: 36px;
      font-weight: 700;
      line-height: 15.57px;
      text-align: center;
      font-family: Inter;
    }
    .month {
      font-size: 12px;
      font-weight: 400;
      line-height: 19.2px;
      text-align: center;
      font-family: Inter;
    }
    .bottomBorder {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      position: relative;
      top: 9%;
    }
    .bottomBorder2 {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      position: relative;
      top: 43%;
    }
    .modelTitle {
      font-size: 12px;
      font-weight: 400;
      line-height: 19.2px;
      text-align: justify;
      width: 165px;
      margin-left: 20%;
    }
    .imgWrapper {
      height: 900px;
      overflow: hidden;
      position: relative;
      cursor: pointer;
    }
    .buyNow {
      position: absolute;
      left: 43%;
      top: 235px;
      transform: translate(-50%, -50%);
      height: 41px;
      width: 175px;
      font-size: 12px;
      line-height: 19.2px;
    }
    .buyNowSelected {
      position: absolute;
      left: 43%;
      top: 220px;
      transform: translate(-50%, -50%);
      height: 41px;
      width: 175px;
      font-size: 12px;
      line-height: 19.2px;
      background: #000000;
    }
    .buyNow2 {
      position: absolute;
      width: 180px;
      height: 40px;
      background: #000;
      color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      /* z-index: 9999; */
      text-align: center;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 32%;
    }
    .buyBtn {
      position: relative;
      top: 10px;
      font-size: 12px;
      font-weight: 400;
      /* line-height: 19.2; */
      text-align: center;
    }
    .modelviews {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      // line-height: 36px;
      margin-top: -8px;
      display: flex;
      align-items: center;
      letter-spacing: 0.25px;

      color: #999999;
    }

    .vectorArea {
      position: absolute;
      top: 38%;
      left: 10%;
    }
    .vectorArea2 {
      position: absolute;
      top: 71%;
      left: 6%;
    }
    .hostedModels {
      padding-left: 10px;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0px;
      position: relative;
      bottom: 2px;
      font-family: Inter;
    }
    .gamefied {
      padding-left: 10px;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0px;
      position: relative;
      bottom: 2px;
      font-family: Inter;
    }
    .paddingTop {
      padding-top: 8px;
    }
    .paddingTop2 {
      padding-top: 15px;
    }
    .varient {
      font-weight: 600;
      font-size: 12px;
      padding-left: 10px;
      font-family: Inter;
    }
    .varient2 {
      font-weight: 600;
      font-size: 12px;
      font-family: Inter;
      // padding-left: 10px;
    }
    // img {
    //   transition: 0.3s;
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    //   width: 80%;
    // }
    // &:hover {
    //   img {
    //     transition: 0.3s;
    //     transform: translate(-50%, -50%) scale(1.1);
    //   }
    //   .themeGrayBtn {
    //     transition: 0.3s;
    //     background-color: rgba(146, 180, 211, 0.8);
    //     color: #fff;
    //   }
    // }
  }

  .pricingCard {
    // height: 100%;
    // background: #ffffff;
    // box-shadow: 1px 2px 4px 4px rgba(0, 0, 0, 0.25);
    width: 272px;
    height: 730px;

    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: 0.3s;

    .beginner {
      font-size: 18px;
      font-weight: 500;
      line-height: 21.78px;
      text-align: center;
      font-family: Inter;
    }
    .price {
      font-size: 36px;
      font-weight: 700;
      line-height: 15.57px;
      text-align: center;
      font-family: Inter;
    }
    .month {
      font-size: 20px;
      font-weight: 500;
      line-height: 19.2px;
      text-align: center;
      font-family: Inter;
    }
    .bottomBorder {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      position: relative;
      top: 9%;
    }
    .bottomBorder2 {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      position: relative;
      top: 43%;
    }
    .modelTitle {
      font-size: 12px;
      font-weight: 400;
      line-height: 19.2px;
      text-align: justify;
      width: 165px;
      margin-left: 20%;
    }
    .imgWrapper {
      height: 900px;
      overflow: hidden;
      position: relative;
      cursor: pointer;
    }
    .buyNow {
      //   position: absolute;
      // width: 180px;
      // height: 40px;
      // background: #FFFFFF;
      // border: 1px solid rgba(0, 0, 0, 0.12);
      // border-radius: 8px;
      // /* z-index: 9999; */
      // text-align: center;
      // transform: translate(-50%, -50%);
      // left: 50%;
      // top: 32%;
      position: absolute;
      left: 43%;
      top: 235px;
      transform: translate(-50%, -50%);
      height: 41px;
      width: 175px;
      font-size: 12px;
      line-height: 19.2px;
    }
    .buyNow2 {
      position: absolute;
      width: 180px;
      height: 40px;
      background: #000;
      color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      /* z-index: 9999; */
      text-align: center;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 32%;
    }
    .buyBtn {
      position: relative;
      top: 10px;
      font-size: 12px;
      font-weight: 400;
      /* line-height: 19.2; */
      text-align: center;
    }
    .modelviews {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      // line-height: 36px;
      margin-top: -8px;
      display: flex;
      align-items: center;
      letter-spacing: 0.25px;

      color: #999999;
    }
    .vectorArea {
      position: absolute;
      top: 40%;
      left: 10%;
    }
    .vectorArea2 {
      position: absolute;
      top: 73%;
      left: 6%;
    }
    .hostedModels {
      padding-left: 10px;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0px;
      position: relative;
      bottom: 2px;
      font-family: Inter;
    }
    .gamefied {
      padding-left: 10px;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0px;
      position: relative;
      bottom: 2px;
      font-family: Inter;
    }
    .paddingTop {
      padding-top: 8px;
    }
    .paddingTop2 {
      padding-top: 15px;
    }
    .varient {
      font-weight: 600;
      font-size: 12px;
      padding-left: 10px;
      font-family: Inter;
    }
    .varient2 {
      font-weight: 600;
      font-size: 12px;
      font-family: Inter;
      // padding-left: 10px;
    }
    // img {
    //   transition: 0.3s;
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    //   width: 80%;
    // }
    // &:hover {
    //   img {
    //     transition: 0.3s;
    //     transform: translate(-50%, -50%) scale(1.1);
    //   }
    //   .themeGrayBtn {
    //     transition: 0.3s;
    //     background-color: rgba(146, 180, 211, 0.8);
    //     color: #fff;
    //   }
    // }
  }

  .modelCard {
    height: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: 0.3s;
    .modelTitle {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      margin-bottom: 14px;
      font-family: Inter;
    }
    .imgWrapper {
      height: 250px;
      overflow: hidden;
      position: relative;
      cursor: pointer;
    }
    .modelviews {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      // line-height: 36px;
      margin-top: -8px;
      display: flex;
      align-items: center;
      letter-spacing: 0.25px;

      color: #999999;
    }
    img {
      transition: 0.3s;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
    }
    &:hover {
      img {
        transition: 0.3s;
        transform: translate(-50%, -50%) scale(1.1);
      }
      .themeGrayBtn {
        transition: 0.3s;
        background-color: rgba(146, 180, 211, 0.8);
        color: #fff;
      }
    }
  }

  .browseHead {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: -0.5px;
  }

  .helpYouRight {
    height: 577px;
    background: rgb(141, 140, 186);
    border-radius: 16px 0px 0px 16px;
    height: 559 px;
    /* background: rgb(57, 105, 150); */
    // margin-right: -50%;
  }
  .sectionInnerSpacingBlogs {
    padding: 10px 0;
    margin-top: -70px;
  }
  .sectionInnerSpacingAr {
    // padding: 150px 0;
    padding: 90px 0;
    text-align: center;
  }
  .sectionInnerSpacingBrowse {
    padding: 0px 0;
  }

  .sectionInnerSpacing {
    padding: 55px 0;
  }
  .sectionInnerSpacingPricing {
    padding: 0px 0;
  }
  .sectionInnerSpacingAbout1 {
    padding: 0px 0;
  }
  .sectionInnerSpacingAbout2 {
    padding: 22px 0;
  }
  .serviceHead {
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    /* identical to box height, or 150% */
    // display: flex;
    align-items: center;
    letter-spacing: -0.5px;
  }
  .serviceSubHead {
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    /* or 150% */
    // display: flex;
    align-items: center;
    letter-spacing: -0.25px;
  }
  .serviceSubHeadResp {
    font-weight: normal;
    font-size: 17px;
    line-height: 36px;
    /* or 150% */
    // display: flex;
    align-items: center;
    letter-spacing: -0.25px;
  }

  .serviceWrapper {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .serviceTitle {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 36px;
      /* identical to box height, or 150% */
      // display: flex;
      align-items: center;
      letter-spacing: 0.25px;
      margin-bottom: 16px;
    }
    .serviceDescription {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 32px;
      text-transform: capitalize;
    }
    .serviceBtn {
      position: relative;
      width: 50%;
      height: 40px;
      left: 0px;
      top: 60px;
      font-weight: bold;
      font-size: 18px;
      text-transform: none;
      transition: 0.3s;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.2px;

      background: #bdbdbd;
      border-radius: 7px;
    }
    &:hover {
      .serviceBtn {
        transition: 0.3s;
        background-color: rgba(146, 180, 211, 0.8);
        color: #fff;
      }
    }
  }

  .pricingTitle {
    font-style: normal;
    font-weight: 900;
    font-size: 64px;
    line-height: 77px;
    /* identical to box height */
    align-self: center;
    text-align: center;
    letter-spacing: -1px;
    padding-bottom: 100px;
  }
  .priceTableWrapper {
    background: #ffffff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    padding: 0 64px 64px 64px;
    .priceTabHead {
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 48px;
      height: 113px;
      display: flex;
      align-items: center;
      letter-spacing: -0.5px;
      color: #adadad;
      border-bottom: 1px solid rgba(205, 205, 205, 0.5);
    }
    .planTitle {
      align-items: flex-end;
    }
    .alignCenter {
      align-self: center;
      justify-content: center;
    }
    .priceTabData {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      height: 113px;
      text-align: center;
      display: flex;
      align-self: center;
      text-align: center;
      align-items: center;
      justify-content: center;
      letter-spacing: -0.5px;
      flex-direction: column;
      color: #000000;
      border-bottom: 1px solid rgba(205, 205, 205, 0.5);
    }
    .buy_now {
      background-color: #bdbdbd;
      color: #fff;
      box-shadow: none;
      text-transform: none;
      font-weight: 700;
      padding: 8px 30px;
      border-radius: 10px;
      &:hover {
        transition: 0.3s;
        background-color: rgba(146, 180, 211, 0.8);
        color: #fff;

        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
          0 1px 2px 0 rgba(0, 0, 0, 0.06);
      }
    }
  }
  .noBorder {
    border: none !important;
  }
  .VAS {
    .VAStitle {
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 48px;
      letter-spacing: -0.5px;
      color: #797979;
      position: relative;

      &::before {
        content: "";
        height: 25%;
        width: 10%;
        position: absolute;
        bottom: 10%;
        left: 50%;
        background: #eaeaea;
        border-radius: 30px;
        transform: translateX(-50%);
        z-index: -1;
      }
    }
    .VASsubtitle {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 32px;
      color: #2d3436;
      margin-top: 8px;
    }
    .VASwrapper {
      padding: 48px;
      .VASitem {
        border: 1px solid #d0d0d0;
        box-sizing: border-box;
        border-radius: 15px;
        width: 75%;
        padding: 16px 0;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 48px;
        letter-spacing: -0.5px;
        margin: 0 auto;
        color: #4e4e4e;
      }
    }
    .touchBtnWrapper {
      padding: 16px;
      .touchBtn {
        background: #bdbdbd;
        border-radius: 15px;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 36px;
        letter-spacing: 0.25px;
        color: #ffffff;
        padding: 12px 40px;
        text-transform: none;
      }
    }
  }
  .footer {
    position: relative;
    overflow: hidden;
    .bgElement {
      position: absolute;
      width: 300%;
      top: 64px;
      left: -100px;
      right: 0;
      bottom: 0;
      background: #d3e1ed;
      transform: rotate(-3deg);
      z-index: -1;
      opacity: 0.4;
    }
    .followTitle,
    .infoTitle {
      font-style: normal;
      font-weight: 800;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 2.5px;
      text-transform: uppercase;
      color: #adadad;
    }
    .infoTitle {
      margin-top: 32px;
    }
    .iconWrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .followicon {
        font-size: 24px;
        margin-right: 40px;
        color: #828282;
        margin-top: 16px;
      }
    }
    .infoWrapper {
      .infoItem {
        margin: 20px 0;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.25px;
        color: #2d3436;
        cursor: pointer;
      }
    }
    .email {
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 48px;
      letter-spacing: -0.5px;
      /* Gray 3 */
      color: #828282;
    }
    .contactInput {
      background: #c2c2c240;
      border-radius: 16px;
      border: none;
      padding: 12px 16px;
      font-size: 18px;
      margin: 12px 0;
      border: 2px solid transparent;
      width: 85%;
      cursor: pointer;
      &:focus {
        border: 2px solid #828282;
        outline: none;
      }
    }
    .inputIcon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 12%;
      font-size: 22px;
      color: #b3b3b3;
    }
    .messageInput {
      height: 44px;
      width: 90%;
      font-family: Inter;
      font-style: normal;
    }
    .messageInputIcon {
      right: 8%;
      top: 40px !important;
    }
    .submitBtn {
      margin: 12px 0;
      padding: 16px 50px;
      background: #828282;
      box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
        0px 16px 32px rgba(38, 50, 56, 0.08);
      border-radius: 16px;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 0.25px;
      color: #ffffff;
      text-shadow: 0px 2px 4px rgba(38, 50, 56, 0.16),
        0px 4px 8px rgba(38, 50, 56, 0.08);
    }
  }
  .notiMessage {
    text-transform: uppercase;
  }

  .animateFadeUp {
    opacity: 0;
    animation: fadeUp 1s 1s cubic-bezier(0.18, 0.56, 0.64, 0.94) forwards;
  }

  @keyframes fadeUp {
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  .animateFadeUpDelay {
    opacity: 0;
    animation: fadeUp 1.5s 1s cubic-bezier(0.18, 0.56, 0.64, 0.94) forwards;
  }

  @keyframes fadeUp {
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .animateFadeRightDelay {
    opacity: 0;
    animation: fadeRight 1.5s 1s cubic-bezier(0.18, 0.56, 0.64, 0.94) forwards;
  }

  @keyframes fadeRight {
    0% {
      transform: translateX(100px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  .animateFadeLeftDelay {
    opacity: 0;
    animation: fadeLeft 1.5s 1s cubic-bezier(0.18, 0.56, 0.64, 0.94) forwards;
  }

  @keyframes fadeLeft {
    0% {
      transform: translateX(-100px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .animateRotate {
    opacity: 0;
    animation: rotate 1.5s 1s cubic-bezier(0.18, 0.56, 0.64, 0.94) forwards;
  }

  @keyframes rotate {
    0% {
      transform: translate(100%, 100%) rotate(100deg);
      opacity: 0;
    }
    100% {
      transform: translate(0, 0) rotate(-45deg);
      opacity: 0.4;
    }
  }
}

.viewInYourSpace {
  background-color: #fff;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 16px;
  border: none;
  display: flex;
  width: 236px;
  flex-direction: row;
  align-items: center;
  padding: 0 12px;
  margin-bottom: 48px;
  justify-content: center;
  &:hover {
    // background-color: rgba(146, 180, 211, 0.8);
    background-color: #ececec;
  }
}

@media (max-width: 768px) {
  #root {
    .serviceWrapper {
      .serviceBtn {
        width: 80%;
      }
    }
  }
}

#qrCodeWrapper {
  height: 250px;
  width: 250px;
  margin: 0 auto;
}

#qrtext {
  font-size: 12px;
  text-align: center;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  position: absolute;
  bottom: 38px;
  left: 50%;
  padding: 6px 8px;
  transform: translateX(-50%);
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  background-color: #fff;
  /* color: #fff; */
  // background-color: #bdbdbd;
  &:hover {
    // box-shadow: 1px 4px 4px 1px rgba(0, 0, 0, 0.25);
    background-color: #ececec;
    // transition: 0.3s;
    // color:#fff
  }
}

/* The Modal (background) */
.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 3000;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  /* 15% from the top and centered */
  padding: 32px 20px;
  transform: translate(-50%, -200%);
  transition: all 0.4s;
  border: 1px solid #888;

  z-index: 3300 !important;
  border-radius: 16px;
  text-align: center;
  /* Could be more or less, depending on screen size */
}
.modal-content h1 {
  margin: 4px;
}
.contentArea {
  width: 300px;
  margin: 0 auto;
}

.modal-content p {
  width: 300px;
  margin: 8px auto;
}

.slideIn {
  transform: translate(-50%, -50%);
  transition: 0.4s;
  opacity: 1;
}

.closeBtn {
  display: inline-block;
  margin: 8px auto;
  border-radius: 8px;
  padding: 12px 32px;
  min-width: 200px;
  cursor: pointer;
  /* background-color: #4a4fdf; */
  font-size: 14px;
  /* color: #fff; */
  width: 100px;
  border: 1px solid #222;
}

.modelViewer,
.modelViewer .variant__wrapper,
.variant__wrapper {
  ::-webkit-scrollbar {
    width: 1px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ddd;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .brand {
    position: absolute;
    z-index: 999;
    // top: 8px;
    // left: 32px;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 56px;
    display: flex;
    align-items: flex-end;
    letter-spacing: -1px;
    color: #2d3436;
    a {
      color: inherit;
      text-decoration: none;
    }
  }
  .card {
    background: #ffffff;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 12px;
    margin-bottom: 8px;
    // width: 75%;
    width: 88%;
    &:hover {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      // background-color: rgba(146, 180, 211, 0.8);
    }
  }
  .cardFirstPreview {
    background: #ffffff;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 12px;
    margin-bottom: 8px;
    // width: 75%;
    width: 88%;
    &:hover {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      // background-color: rgba(146, 180, 211, 0.8);
      // background-color: #ececec;
    }
  }
  .cardPreview {
    background: #ffffff;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 12px;
    margin-bottom: 8px;
    width: 88%;
    &:hover {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      // background-color: rgba(146, 180, 211, 0.8);
      // background-color: #ececec;
    }
  }
  .modelTitlePreview {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    display: flex;
    align-items: flex-end;
    letter-spacing: -1px;
    margin-bottom: 0px;
    line-height: 12px;
    color: #000000;
  }
  .modelTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    display: flex;
    align-items: flex-end;
    letter-spacing: -1px;
    margin-bottom: 0px;
    line-height: normal;
    color: #000000;
  }
  .modelTitleAbout {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    display: flex;
    align-items: left;
    letter-spacing: -1px;
    margin-bottom: 0px;
    line-height: normal;
    color: #000000;
  }
  .subtitlePreview {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 12px;
    margin-left: 0px;
    margin-bottom: 10px;
    display: flex;
    align-items: flex-end;
    letter-spacing: -1px;
  }
  .subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: flex-end;
    letter-spacing: -1px;
    margin-bottom: 0px;
  }
  .buyNow {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    /* or 133% */
    display: flex;
    align-items: flex-end;
    letter-spacing: -1px;

    color: #000000;
    padding-top: 17px;
  }
  .buyNowPreview {
    font-size: 18px;
    line-height: 24px;
    margin-left: 0px;
    display: flex;
    align-items: flex-end;
    letter-spacing: -1px;
    margin-bottom: 5px;
    color: #000000;
    font-weight: 600;
    cursor: pointer;
  }
  .buy_sub {
    font-weight: 100;
    font-size: 12px;
    /* or 133% */
    display: flex;
    align-items: flex-end;
    letter-spacing: -1px;
    margin: 0;
    text-decoration: none;
    font-weight: 400;
  }
  .buy_sub_link {
    font-weight: 100;
    font-size: 12px;
    display: flex;
    align-items: flex-end;
    letter-spacing: -1px;
    margin-top: 5px;
    // cursor: pointer;
    color: #000;
  }
  .buy_subPreview {
    font-weight: normal;
    font-size: 14px;
    display: flex;
    align-items: flex-end;
    letter-spacing: 0px;
    margin: 0;
  }
  .fixBox {
    max-height: 160px;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    justify-content: flex-start;
    transition: 0.3s;
    flex-wrap: wrap;
  }
  .variant_head {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 56px;
    /* identical to box height, or 311% */
    display: flex;
    align-items: flex-end;
    letter-spacing: -1px;
    color: #565656;
  }
  .paletteBox {
    position: relative;
    width: 50px;
    height: 50px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin: 6px;
    transition: 0.3s;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
      transition: 0.3s;
    }
  }
  .variant_head_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .selected {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.3;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
  #qrtext {
    padding: 16px 12px;
    // margin-left: -29px;
    margin-left: -9px;
    width: 88%;
    background-color: #fff;
    &:hover {
      // box-shadow: 1px 4px 4px 1px rgba(0, 0, 0, 0.25);
      background-color: #ececec;
      // transition: 0.3s;
      // color:#fff
    }
    .view_space_btn {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      /* or 117% */
      display: flex;
      align-items: flex-end;
      letter-spacing: -1px;
      align-self: start;
      color: #000000;
      margin: 0;
      margin-left: 8px;

      // box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
      // &:hover {
      //   box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
      // }
    }
  }
  .iconWrapper {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    box-sizing: border-box;
    img {
      height: 34px;
      margin: 0 12px;
    }
    .iconTitle {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 12px;
      /* or 100% */
      display: flex;
      align-items: flex-end;
      letter-spacing: -1px;

      color: #000000;
    }
    .shareBtn {
      // width: 56px;
      // height: 48px;
      width: 40px;
      height: 40px;
      background: #ffffff;
      box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      // display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }

  .OverFlowComponents {
    height: 350px;
    overflow: hidden;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 0px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #ddd;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}
.aboutUs {
  display: flex;
  justify-content: center;
  align-items: center;
}
.SignInWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 100px;
  .signIn {
    text-align: center;
    margin: 50px auto;
    .mainHeading {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 48px;
      margin-bottom: 46px;
      align-items: center;
      text-align: center;
      letter-spacing: -0.5px;

      color: #2d3436;
    }
    .inputWrapper {
      position: relative;
      // margin-bottom: 19px;
      // display: flex;
      flex-direction: row;
      .spanTag {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 32px;
        cursor: pointer;
        align-items: center;
        text-align: right;

        color: #2d3436;
      }
      .emailTag {
        margin-right: 12px;
        margin-top: 14px;
        display: inline-block;
      }
      .passTag {
        margin-right: 12px;
        margin-top: 14px;
        display: inline-block;
        margin-left: -40px;
      }
      .Input {
        background: #ffffff;
        box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        margin-bottom: 16px;
        border: 1px solid #ddd;
        outline: none;
        padding: 12px;
        cursor: pointer;
        width: 282px;
        height: 28px;
        position: relative;
      }
      .hidePass {
        position: absolute;
        color: #c2c2c2;
        font-size: 15px;
        right: 15px;
        top: 18px;
        cursor: pointer;
      }
    }
    .forgotPass {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 13px;
      cursor: pointer;

      align-items: center;
      // text-align: right;
      margin-left: 63%;
      margin-bottom: 21px;
      text-decoration-line: underline;

      color: #2d3436;
    }
    .submitBtn {
      background: #c2c2c2;
      color: #fff;
      box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
        0px 16px 32px rgba(38, 50, 56, 0.08);
      border-radius: 10px;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      line-height: 36px;
      margin-left: 56px;
      font-size: 22px;
      height: 43px;
      align-items: center;
      text-align: center;
      letter-spacing: 0.25px;
      width: 300px;
      cursor: pointer;
      color: #ffffff;
      text-transform: capitalize;
      text-shadow: 0px 2px 4px rgba(38, 50, 56, 0.16),
        0px 4px 8px rgba(38, 50, 56, 0.08);
      margin-bottom: 17px;
      &:hover {
        transition: 0.3s;
        background-color: rgba(146, 180, 211, 0.8);
        color: #fff;

        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
          0 1px 2px 0 rgba(0, 0, 0, 0.06);
      }
    }
    .signwithgoogle {
      padding-top: 4px;
      height: 45px;
      background: #ffffff;
      position: relative;
      border-radius: 2px;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      cursor: pointer;
      color: #757575;
      width: 217px;
      margin-left: 62px !important;

      // margin: 20px 20px;
      margin-bottom: 13px;
      border: 1px solid #9b9090;
      // margin-left: 66px;
      // box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.084),
      // 0px 1px 1px rgba(0, 0, 0, 0.168);
    }
    .microsoftLogin {
      margin-left: 62px !important;
      margin-bottom: 32px !important;
    }
    .signInor {
      margin-top: 20px;
      margin-bottom: 20px;
      margin-left: 53px;
    }
    .newUser {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 48px;

      align-items: center;
      text-align: center;
      letter-spacing: -0.5px;

      color: #2d3436;
      margin-left: 62px;
      margin-top: 11px;
      margin-bottom: 34px;
    }
    .regBtn {
      background: #bdbdbd;
      /* 16 dp • Dark shadow */

      box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
        0px 16px 32px rgba(38, 50, 56, 0.08);
      border-radius: 16px;
      margin-left: 41px;
    }
    .newHere {
      margin-bottom: -19px;
    }
  }
}
.getInTouch {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 97px;
  .fileList {
    display: inline-flex;
    position: relative;
    p {
      margin: 0px 10px;
      border: 1px solid #bdbdbd;
      padding: 8px 30px;
      border-radius: 30px;
      cursor: pointer;
      font-size: 13px;
      transition: 0.3s;

      &:hover {
        transition: 0.3s;
        border-color: rgba(146, 180, 211, 0.8);

        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
          0 1px 2px 0 rgba(0, 0, 0, 0.06);
      }
    }
    .Close {
      font-size: 13px;

      /* margin-left: -34px; */
      position: absolute;
      right: 17px;
      top: 12px;
      color: #918787;
      cursor: pointer;
    }
  }
  .mainHeading {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 24px;
    /* or 75% */

    // display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.25px;

    color: #000000;
  }
  .spanTag {
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 75% */
    cursor: pointer;
    display: flex;
    align-items: center;
    letter-spacing: 0.25px;
    // margin-left: 19%;
    color: #000000;
  }
  .Input {
    width: 704px;
    // height: 28px;
    background: #ffffff;
    box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin-bottom: 0px;
    border: 1px solid #ddd;
    outline: none;
    // width: 225px;
    padding: 12px;
    font-family: "Inter";
    cursor: pointer;
  }
  .contactRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
  }
  .submitBtn {
    background: #9b9b9b;
    padding: 16px 40px;
    box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
      0px 16px 32px rgba(38, 50, 56, 0.08);
    border-radius: 15px;
    margin-top: 52px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;

    align-items: center;
    text-align: center;
    letter-spacing: 0.25px;

    cursor: pointer;
    color: #ffffff;
    text-transform: capitalize;
    width: 166px;
    margin-left: 21px;
    text-shadow: 0px 2px 4px rgba(38, 50, 56, 0.16),
      0px 4px 8px rgba(38, 50, 56, 0.08);
    &:hover {
      transition: 0.3s;
      background-color: rgba(146, 180, 211, 0.8);
      color: #fff;

      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.06);
    }
  }
  .upload {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 36px;
    border: none;
    align-items: center;
    text-align: center;
    letter-spacing: 0.25px;
    cursor: pointer;
    color: #ffffff;

    text-shadow: 0px 2px 4px rgba(38, 50, 56, 0.16),
      0px 4px 8px rgba(38, 50, 56, 0.08);
    background: #bdbdbd;
    box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
      0px 16px 32px rgba(38, 50, 56, 0.08);
    border-radius: 15px;
    padding: 6px 29px;
    margin-top: 13px;
    text-transform: capitalize;
    &:hover {
      transition: 0.3s;
      background-color: rgba(146, 180, 211, 0.8);
      color: #fff;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.06);
    }
  }
  .flexRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .validate {
    text-align: right;
    margin-top: 30px;
  }
}

.blogCard {
  height: 100%;
  // background: #ffffff;
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  // border-radius: 15px;
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.3s;
  cursor: pointer;
  .modelTitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    // display: flex;
    margin-bottom: 14px;
  }
  .imgBackground {
    border-radius: 16px;
  }
  .imgWrapperOneResp {
    height: 180px;
    width: 250px;
    overflow: hidden;
    position: relative;
    border-radius: 26px;
    img {
      border-radius: 20px;
      // height: 166px;
    }
    // &:hover {
    //   img {
    //     transition: 0.3s;
    //     transform: translate(-50%, -50%) scale(1.1);
    //     border-radius: 26px;
    //   }

    // }
  }
  .imgWrapperOne {
    height: 275px;
    overflow: hidden;
    position: relative;
    border-radius: 26px;
    img {
      border-radius: 20px;
      // height: 166px;
    }
    // &:hover {
    //   img {
    //     transition: 0.3s;
    //     transform: translate(-50%, -50%) scale(1.1);
    //     border-radius: 26px;
    //   }

    // }
  }
  .imgWrapperLanding {
    height: 275px;
    width: 383px;
    border-radius: 26px;
    overflow: hidden;
    position: relative;
    img {
      border-radius: 20px;
      height: 166px;
    }
    &:hover {
      img {
        transition: 0.3s;
        transform: translate(-50%, -50%) scale(1.1);
      }
      // .themeGrayBtn {
      //   transition: 0.3s;
      //   background-color: rgba(146, 180, 211, 0.8);
      //   color: #fff;
      // }
    }
  }
  .imgWrapper {
    height: 250px;
    overflow: hidden;
    position: relative;
    img {
      border-radius: 20px;
      height: 166px;
    }
    &:hover {
      img {
        transition: 0.3s;
        transform: translate(-50%, -50%) scale(1.1);
      }
      // .themeGrayBtn {
      //   transition: 0.3s;
      //   background-color: rgba(146, 180, 211, 0.8);
      //   color: #fff;
      // }
    }
  }
  .modelviews {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    // line-height: 36px;
    margin-top: -8px;
    display: flex;
    align-items: center;
    letter-spacing: 0.25px;

    color: #999999;
  }
  img {
    transition: 0.3s;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
  }
}
.userDashboard {
  margin: 20px auto !important;
  .sidebar {
    text-align: center;
    .sidebarItem {
      background: #ebebeb;
      width: 123px;
      height: 123px;
      display: inline-flex;
      border-radius: 16px;
    }
    .nameComp {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 48px;
      border-bottom: 0.4px solid #bdbdbd;
      padding-bottom: 10px;
      align-items: center;
      text-align: center;
      letter-spacing: -0.5px;

      margin-top: 10px;

      color: #2d3436;
    }
    .performance {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 36px;
      /* identical to box height, or 300% */

      display: flex;
      align-items: center;
      letter-spacing: 0.25px;

      /* Black */

      color: #2d3436;

      /* Inside Auto Layout */

      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 1.5px 0px;
    }
    .spanTag {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 32px;
      /* or 229% */
      cursor: pointer;
      /* Black */

      color: #2d3436;

      /* Inside Auto Layout */

      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 14px 0px;
    }
    .productBtn {
      background: #ebebeb;
      border-radius: 15px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 48px;
      text-transform: capitalize;
      display: flex;
      align-items: center;

      text-align: center;
      letter-spacing: -0.5px;

      color: #666666;
      &:hover {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
          0 1px 2px 0 rgba(0, 0, 0, 0.06);
        transition: 0.3s;
        background-color: rgba(146, 180, 211, 0.8);
        color: #fff;
      }
    }
    .onHoverLink {
      text-decoration: none;
      &:hover {
        border-bottom: 1px solid rgba(146, 180, 211, 0.8);
        transition: 0.3s;
      }
    }
  }
  .rightsidebar {
    text-align: center;
    .textBelowimg {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 32px;
      /* or 178% */
      margin-bottom: 40px;
      align-items: center;
      text-align: center;

      /* Black */

      color: #2d3436;
    }

    .CreateBtn {
      background: #bdbdbd;
      /* 16 dp • Dark shadow */

      box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
        0px 16px 32px rgba(38, 50, 56, 0.08);
      border-radius: 16px;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 36px;
      /* or 150% */
      padding: 10px;
      align-items: center;
      text-align: center;
      letter-spacing: 0.25px;

      /* White */

      color: #ffffff;

      /* 2 dp • Dark shadow */

      text-shadow: 0px 2px 4px rgba(38, 50, 56, 0.16),
        0px 4px 8px rgba(38, 50, 56, 0.08);
    }
  }
  .SettingBtn {
    background: #bdbdbd;
    border-radius: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 48px;
    /* identical to box height, or 267% */
    width: 25%;
    // display: flex;
    align-items: center;
    text-transform: capitalize;

    text-align: center;
    letter-spacing: -0.5px;
    box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
      0px 16px 32px rgba(38, 50, 56, 0.08);
    margin-bottom: 30px;
    color: #fff;
    &:hover {
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.06);
      transition: 0.3s;
      background-color: rgba(146, 180, 211, 0.8);
      color: #fff;
    }
  }
  .SettingBtnProfile {
    background: #bdbdbd;
    border-radius: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 48px;
    /* identical to box height, or 267% */
    width: 20%;
    // display: flex;
    align-items: center;
    text-transform: capitalize;

    text-align: center;
    letter-spacing: -0.5px;
    box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
      0px 16px 32px rgba(38, 50, 56, 0.08);
    margin-bottom: 30px;
    color: #fff;
    &:hover {
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.06);
      transition: 0.3s;
      background-color: rgba(146, 180, 211, 0.8);
      color: #fff;
    }
  }
  .ProfilePictureSection {
    text-align: center;
    margin-top: -15px;
    margin-left: 40px;
    .text {
      padding: 10px;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 32px;
      // margin-bottom: 20px;
      /* identical to box height, or 133% */

      align-items: center;

      color: #000000;
    }
    .profileright {
      width: 123px;
      height: 123px;
      margin-left: 50px;
      background: #ebebeb;
      border-radius: 61.5px;
    }
    .profilePic {
      background: #bdbdbd;
      border-radius: 16px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      // line-height: 48px;
      /* identical to box height, or 267% */
      width: 70%;
      // display: flex;
      align-items: center;
      text-transform: capitalize;
      text-align: center;
      letter-spacing: -0.5px;
      box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
        0px 16px 32px rgba(38, 50, 56, 0.08);
      margin-bottom: 30px;
      color: #fff;
      &:hover {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
          0 1px 2px 0 rgba(0, 0, 0, 0.06);
        transition: 0.3s;
        background-color: rgba(146, 180, 211, 0.8);
        color: #fff;
      }
    }
  }
  .representTitle {
    border-bottom: 1px solid #ddd;
    padding: 10px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 40px;
    padding-left: 45px;
    position: relative;
    width: 100%;
    align-items: center;

    color: #000000;
    .userIcon {
      position: absolute;
      left: 8px;
      top: 13px;
    }
  }
  .saveChanges {
    background: #bdbdbd;
    border-radius: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    text-transform: capitalize;
    font-size: 18px;
    line-height: 48px;
    /* identical to box height, or 267% */
    width: 60%;
    // display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.5px;
    box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
      0px 16px 32px rgba(38, 50, 56, 0.08);
    margin-bottom: 30px;
    color: #fff;
    &:hover {
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.06);
      transition: 0.3s;
      background-color: rgba(146, 180, 211, 0.8);
      color: #fff;
    }
  }
  .profilePic {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 36px;
    /* identical to box height, or 200% */

    // display: flex;
    align-items: center;
    letter-spacing: 0.25px;

    color: #000000;
  }
  .inputWrapper {
    display: inline-flex;
    position: relative;
    .spanTag {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 32px;
      /* or 178% */
      cursor: pointer;
      text-align: right;

      /* Black */

      color: #2d3436;
    }
    .emailTag {
      margin-left: -18px;
      margin-right: 10px;
      margin-top: 3px;
    }
    .passTag {
      margin-left: -52px;
      margin-right: 10px;
      margin-top: 3px;
    }
    .Input {
      background: #ffffff;
      box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      margin-bottom: 16px;
      border: 1px solid #ddd;
      outline: none;
      width: 282px;
      padding: 12px;
      cursor: pointer;
      margin-left: 10px;
      font-family: Inter;
    }
    .InputSelect {
      box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
      width: 100%;
      border-radius: 5px;
      margin-bottom: 16px;
      outline: none;
      width: 573px;
      padding: 5px;
      cursor: pointer;
    }
    .rmsc .dropdown-container {
      border: none;
    }
    .rmsc .dropdown-heading .dropdown-heading-value {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 32px;
    }
    .fileTag {
      position: absolute;
      left: 84px;
      cursor: pointer;
      top: 13px;
      background: #828282;
      width: 27%;
      padding: 9px;
      border-radius: 9px;
      color: white;
      font-size: 18px;
      font-family: "Inter";
      font-weight: 600;
    }
  }
  .profileBox {
    width: 123px;
    height: 123px;

    background: #ebebeb;
    border-radius: 16px;
  }
  .profileright {
    width: 123px;
    height: 123px;

    background: #ebebeb;
    border-radius: 61.5px;
  }
  .spanTag {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 48px;
    /* identical to box height, or 267% */
    cursor: pointer;
    align-items: center;
    text-align: right;
    letter-spacing: -0.5px;

    /* Black */

    color: #2d3436;
  }
  .spanRight {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 48px;
    text-transform: capitalize;
    /* identical to box height, or 267% */

    // display: flex;
    align-items: center;
    letter-spacing: -0.5px;

    /* Black */

    color: #2d3436;
  }
  .selectInvoice {
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 48px;
    /* identical to box height, or 267% */

    align-items: center;
    letter-spacing: -0.5px;

    /* Black */

    color: #2d3436;
  }
  .downloadbtn {
    background: #bdbdbd;
    /* 16 dp • Dark shadow */

    box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
      0px 16px 32px rgba(38, 50, 56, 0.08);
    border-radius: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 36px;
    /* or 200% */

    align-items: center;
    letter-spacing: 0.25px;
    text-transform: capitalize;
    /* White */
    // width: 200px;
    color: #ffffff;

    /* 2 dp • Dark shadow */

    text-shadow: 0px 2px 4px rgba(38, 50, 56, 0.16),
      0px 4px 8px rgba(38, 50, 56, 0.08);
  }
  .editIcon {
    font-size: 17px;
    margin-left: 10px;
    color: #a7a6a6;
    margin-top: 14px;
  }
  .profileBtn {
    background: #e9e9e9;
    border-radius: 11px;
    width: 405px;
    height: 84px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    align-items: center;
    color: #000000;
    text-transform: capitalize;
    margin: 10px;
    &:hover {
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.06);
      transition: 0.3s;
      background-color: rgba(146, 180, 211, 0.8);
      color: #fff;
    }
    .personIcon {
      color: "#8a8a8a";
      font-size: 40px;
      margin-top: 8px;
      &:hover {
        transition: 0.3s;
        color: #fff;
      }
    }
  }

  .cancelPlanBtn {
    background: #e9e9e9;
    border-radius: 11px;
    width: 150px;
    height: 50px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 32px;
    align-items: center;
    color: #000000;
    text-transform: capitalize;
    margin: 10px;
    &:hover {
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.06);
      transition: 0.3s;
      background-color: rgba(146, 180, 211, 0.8);
      color: #fff;
    }
    .personIcon {
      color: "#8a8a8a";
      font-size: 40px;
      margin-top: 8px;
      &:hover {
        transition: 0.3s;
        color: #fff;
      }
    }
  }

  // .MuiGrid-spacing-xs-2 {
  //   margin-top: 10px;
  // }
}
.productCreate {
  .mainhead {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;

    display: flex;
    align-items: center;
    letter-spacing: 0.25px;
    margin-bottom: 50px;
    margin-left: 113px;
    color: #2d3436;
  }
  .inputWrapper {
    display: inline-flex;
    position: relative;
    .spanTag {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 32px;
      /* or 178% */
      cursor: pointer;
      text-align: right;

      /* Black */

      color: #2d3436;
    }
    .emailTag {
      margin-left: -18px;
      margin-right: 10px;
      margin-top: 3px;
    }
    .passTag {
      margin-left: -52px;
      margin-right: 10px;
      margin-top: 3px;
    }
    .Input {
      background: #ffffff;
      box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      margin-bottom: 16px;
      border: 1px solid #ddd;
      outline: none;
      width: 573px;
      padding: 12px;
      cursor: pointer;
    }
    .InputSelect {
      box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
      width: 100%;
      border-radius: 5px;
      margin-bottom: 16px;
      outline: none;
      width: 573px;
      padding: 5px;
      cursor: pointer;
    }
    .rmsc .dropdown-container {
      border: none;
    }
    .rmsc .dropdown-heading .dropdown-heading-value {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 32px;
    }
    .fileTag {
      position: absolute;
      left: 84px;
      cursor: pointer;
      top: 13px;
      background: #828282;
      width: 27%;
      padding: 9px;
      border-radius: 9px;
      color: white;
      font-size: 18px;
      font-family: "Inter";
      font-weight: 600;
    }
  }
  .textbelowInput {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    text-align: left;
    // margin-left: 130px;
    margin-bottom: 30px;
    color: #828282;
  }
  .browseBtn {
    background: #828282;
    /* 16 dp • Dark shadow */

    box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
      0px 16px 32px rgba(38, 50, 56, 0.08);
    border-radius: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    margin: 10px;
    width: 30%;
    /* or 178% */

    // display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;
  }
  .checkText {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    /* or 178% */
    text-align: left;
    margin-left: 10px;
    text-transform: capitalize;

    /* Gray 3 */

    color: #828282;
  }
  .CreateBtn {
    background: #696969;
    /* 16 dp • Dark shadow */

    box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
      0px 16px 32px rgba(38, 50, 56, 0.08);
    border-radius: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 36px;
    /* or 150% */
    padding: 10px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.25px;
    width: 30%;
    padding: 5px;
    display: flex;
    margin-left: 25%;
    /* White */
    margin-bottom: 20px;
    color: #ffffff;

    /* 2 dp • Dark shadow */

    text-shadow: 0px 2px 4px rgba(38, 50, 56, 0.16),
      0px 4px 8px rgba(38, 50, 56, 0.08);
  }
  .productCreateRight {
    text-align: center;
    .productContainer {
      padding: 80px !important;
    }
  }
}
.productList {
  .productWrapper {
    background: #ffffff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  }
  .createFirstProductBtn {
    background: #bdbdbd;
    border-radius: 16px;
    margin-top: 30px;
    padding: 10px;
    border: none;
    width: 300px;
    height: 62px;
    color: white;
    font-size: 17px;
    background: #bdbdbd;
    text-transform: capitalize;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
      0px 16px 32px rgba(38, 50, 56, 0.08);
    &:hover {
      transition: 0.3s;
      background-color: rgba(146, 180, 211, 0.8);
      color: #fff;

      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.06);
    }
  }
  .rowheads {
    font-family: Inter;
    font-style: normal;
    font-size: 18px;
    // font-size: 13px;
    line-height: 32px;

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.2px;
    padding: 0px 12px;
    color: #828282;
  }
  .searchinput {
    width: 394px;
    height: 49px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 40px;
    padding-left: 25px;
    // margin-left: 33%;
    outline: none;
    display: flex;
    align-items: center;
    letter-spacing: -0.5px;
    border: 1px solid #f7f5f5;
    color: #999999;

    background: #ffffff;
    box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 32px;
  }
  .serachIcon {
    color: #c6bdbd;
    position: absolute;
    top: 4px;
    right: 4%;
  }
  .productCard {
    height: 100%;
    // background: #ffffff;
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    // border-radius: 15px;
    flex: 1;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    justify-content: space-between;
    transition: 0.3s;
    .modelTitle {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      margin-bottom: 14px;
      text-transform: capitalize;
    }
    // .imgBackground {
    //   // background: #ebebeb;
    //   // border-radius: 16px;
    // }
    .imgWrapper {
      height: 145px;
      overflow: hidden;
      position: relative;

      &:hover {
        img {
          transition: 0.3s;
          transform: translate(-50%, -50%) scale(1.1);
        }
        // .themeGrayBtn {
        //   transition: 0.3s;
        //   background-color: rgba(146, 180, 211, 0.8);
        //   color: #fff;
        // }
      }
    }
    .modelviews {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      // line-height: 36px;
      margin-top: -8px;
      display: flex;
      align-items: center;
      letter-spacing: 0.25px;

      color: #999999;
    }
    img {
      transition: 0.3s;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
      border-radius: 16px;
    }
    .deleteIcon {
      // position: absolute;
      // bottom: 46px;
      // right: 21px;
      color: #999999;
      font-size: 20px;
    }
  }
  .SeeMoreBtn {
    width: 161px;
    height: 45px;

    background: #ebebeb;
    border-radius: 22.5px;
    cursor: pointer;
    text-transform: capitalize;
  }
  .CategoryDiv {
    background: #ffffff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
    padding: 10px;
    margin-top: 20px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    position: relative;
    width: 100%;
    .Heading {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 48px;
      text-transform: capitalize;
      /* identical to box height, or 267% */
      margin-left: 44px;
      display: flex;
      align-items: center;
      letter-spacing: -0.5px;

      /* Black */

      color: #2d3436;
    }
  }
  .MuiAccordion-root {
    position: initial;
  }
  .MuiPaper-elevation1 {
    box-shadow: none;
  }
  .MuiAccordionSummary-root {
    height: 80px;
  }
  .cardWrapper {
    background: #ffffff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
    padding: 20px;
    width: 100%;
    text-align: center;
    // height: 300px;
    overflow: auto;
    .theFurnitureBtn {
      background: #ebebeb;
      border-radius: 30.5px;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 48px;
      /* or 400% */

      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.5px;

      /* Black */
      width: 18%;
      color: #2d3436;
      margin: 5px auto;
    }
  }
}

.editWrapper {
  margin-top: 20px;
  .imageUpload {
    opacity: 0;
  }
  .editLeftWrapper {
    background: #ffffff;
    border: 0.2px solid #e7e3e3;
    // border: 0.2px solid #a8a8a8;
    box-sizing: border-box;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 20px;
    .heading {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 10px;
      display: flex;
      align-items: flex-end;
      letter-spacing: -1px;

      color: #828282;
    }
    .checkboxWrapper {
      display: inline-flex;
      position: relative;
    }
    .checkInput {
      background: #ffffff;
      box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 6px;
      height: 36px;
      width: 36px;
      position: absolute;
      right: -20%;
    }
    .checkBox {
      width: 39.54px;
      height: 32px;

      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      margin-right: 10px;
    }
    .checkText {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 30px;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      letter-spacing: -1px;
      color: #565656;
      position: relative;
    }
    .rowHead {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 56px;
      text-transform: capitalize;
      display: flex;
      align-items: flex-end;
      letter-spacing: -1px;

      color: #565656;
    }
    .uploadbtn {
      background: #bdbdbd;

      box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
        0px 16px 32px rgba(38, 50, 56, 0.08);
      border-radius: 6px;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      // line-height: 36px;

      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.25px;
      margin-top: 10px;
      color: #ffffff;

      text-shadow: 0px 2px 4px rgba(38, 50, 56, 0.16),
        0px 4px 8px rgba(38, 50, 56, 0.08);
    }
  }
  .editRightWrapper {
    .inlineWrapper {
      display: inline-flex;
      flex-wrap: wrap;
    }
    //   .parentDiv{
    //     width: 100%;
    //     height: 100%;
    //     overflow: hidden;
    // }
    ::-webkit-scrollbar {
      width: 0px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #ddd;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .box1 {
      width: 100%;
      height: 88px;
      box-sizing: content-box;
      padding: 10px;
      margin-bottom: 10px;
      background: #ffffff;
      box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
      border-radius: 15px;
    }
    .collapse {
      min-height: 0px;
      height: auto;
      transition-duration: 222ms;
    }
    .downArrow {
      position: absolute;
      right: 10px;
      top: 10px;
      color: #bdbdbd;
      font-size: 30px;
    }
    .smallBox {
      width: 50px;
      height: 50px;
      margin: 0px 10px;
      background: #e2e2e2;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      text-align: center;
      margin-bottom: 17px;
    }
    .compName {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 12px;
      margin-left: 10px;
      margin-bottom: 10px;
      display: flex;
      align-items: flex-end;
      letter-spacing: -1px;

      color: #000000;
    }
    .buyNow {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      margin-left: 10px;
      display: flex;
      align-items: flex-end;
      letter-spacing: -1px;
      margin-bottom: 10px;
      color: #000000;
      padding-top: 17px;
    }
    .fromtext {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 12px;
      /* or 86% */
      // text-align: center;
      margin-left: 10px;
      // display: flex;
      align-items: flex-end;
      letter-spacing: -1px;

      color: #000000;
    }
    .componenttext {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 48px;
      /* identical to box height, or 311% */
      margin-right: 10px;
      display: flex;
      align-items: flex-end;
      letter-spacing: -1px;
      text-transform: capitalize;
      margin-bottom: 20px;
      color: #565656;
    }
    .secondBox {
      width: 50px;
      height: 50px;
      text-align: center;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      margin: 0px 10px;
    }
    .default {
      margin-left: 48px;
      margin-top: 13px;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 12px;
      /* or 86% */

      display: flex;
      align-items: flex-end;
      text-align: center;
      // letter-spacing: -1px;

      color: #5b5b5b;
    }
    .addComp {
      background: #ffffff;
      box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      width: 100%;
      height: 56px;
      cursor: pointer;
      position: relative;
      margin-top: 10px;
      text-align: left;
      padding-left: 24px;
      .text {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 56px;
        cursor: pointer;
        /* identical to box height, or 311% */

        // display: flex;
        align-items: flex-end;
        letter-spacing: -1px;

        color: #565656;
      }
    }
    .saveBtn {
      background: #bdbdbd;
      text-transform: capitalize;

      box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
        0px 16px 32px rgba(38, 50, 56, 0.08);
      border-radius: 16px;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 36px;
      width: 106%;
      margin-top: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.25px;

      color: #ffffff;

      text-shadow: 0px 2px 4px rgba(38, 50, 56, 0.16),
        0px 4px 8px rgba(38, 50, 56, 0.08);
      &:hover {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
          0 1px 2px 0 rgba(0, 0, 0, 0.06);
        transition: 0.3s;
        background-color: rgba(146, 180, 211, 0.8);
        color: #fff;
      }
    }
    .chatBoxEdit {
      // padding-left: 48px;
      margin-top: 30px;
      p {
        font-family: Inter;
        padding-left: 48px;

        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        cursor: pointer;
        display: flex;
        align-items: flex-end;
        letter-spacing: -1px;

        color: #565656;
      }
      span {
        font-family: Inter;
        padding-left: 48px;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        text-transform: capitalize;
        display: flex;
        align-items: flex-end;
        letter-spacing: -1px;
        cursor: pointer;
        margin-bottom: 20px;
        color: #565656;
      }
      .chatBox {
        height: 170px;
        width: 105%;
        background: #ffffff;
        border: 0.5px solid #000000;
        box-sizing: border-box;
        // box-shadow: inset 1px 2px 5px rgba(0 0 0 / 10%);
        border-radius: 15px;
        padding: 10px;
        outline: none;
        &:focus {
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
            0 1px 2px 0 rgba(0, 0, 0, 0.06);
          transition: 0.3s;
          border-color: rgba(146, 180, 211, 0.8);
        }
      }
      .chatBoxResp {
        height: 170px;
        width: 105%;
        background: #ffffff;
        border: 0.5px solid #000000;
        box-sizing: border-box;
        // box-shadow: inset 1px 2px 5px rgba(0 0 0 / 10%);
        border-radius: 15px;
        padding: 10px;
        outline: none;
        &:focus {
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
            0 1px 2px 0 rgba(0, 0, 0, 0.06);
          transition: 0.3s;
          border-color: rgba(146, 180, 211, 0.8);
        }
      }
    }
  }
}

.editWrapperResp {
  margin-top: 440px;
  .imageUpload {
    opacity: 0;
  }
  .editLeftWrapper {
    background: #ffffff;
    border: 0.2px solid #e7e3e3;
    // border: 0.2px solid #a8a8a8;
    box-sizing: border-box;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 20px;
    .heading {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 10px;
      display: flex;
      align-items: flex-end;
      letter-spacing: -1px;

      color: #828282;
    }
    .checkboxWrapper {
      display: inline-flex;
      position: relative;
    }
    .checkInput {
      background: #ffffff;
      box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 6px;
      height: 36px;
      width: 36px;
      position: absolute;
      right: -20%;
    }
    .checkBox {
      width: 39.54px;
      height: 32px;

      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      margin-right: 10px;
    }
    .checkText {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 56px;
      text-transform: capitalize;
      display: flex;
      align-items: flex-end;
      letter-spacing: -1px;

      color: #565656;
    }
    .rowHead {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 56px;
      text-transform: capitalize;
      display: flex;
      align-items: flex-end;
      letter-spacing: -1px;

      color: #565656;
    }
    .uploadbtn {
      background: #bdbdbd;

      box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
        0px 16px 32px rgba(38, 50, 56, 0.08);
      border-radius: 6px;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      // line-height: 36px;

      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.25px;
      margin-top: 10px;
      color: #ffffff;

      text-shadow: 0px 2px 4px rgba(38, 50, 56, 0.16),
        0px 4px 8px rgba(38, 50, 56, 0.08);
    }
  }
  .editRightWrapper {
    .inlineWrapper {
      display: inline-flex;
      flex-wrap: wrap;
    }
    //   .parentDiv{
    //     width: 100%;
    //     height: 100%;
    //     overflow: hidden;
    // }
    ::-webkit-scrollbar {
      width: 0px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #ddd;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .box1 {
      width: 100%;
      height: 88px;
      box-sizing: content-box;
      padding: 10px;
      margin-bottom: 10px;
      background: #ffffff;
      box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
      border-radius: 15px;
    }
    .collapse {
      min-height: 0px;
      height: auto;
      transition-duration: 222ms;
    }
    .downArrow {
      position: absolute;
      right: 10px;
      top: 10px;
      color: #bdbdbd;
      font-size: 30px;
    }
    .smallBox {
      width: 50px;
      height: 50px;
      margin: 0px 10px;
      background: #e2e2e2;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      text-align: center;
      margin-bottom: 17px;
    }
    .compName {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 12px;
      margin-left: 10px;
      margin-bottom: 10px;
      display: flex;
      align-items: flex-end;
      letter-spacing: -1px;

      color: #000000;
    }
    .buyNow {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      margin-left: 10px;
      display: flex;
      align-items: flex-end;
      letter-spacing: -1px;
      margin-bottom: 10px;
      color: #000000;
      padding-top: 17px;
    }
    .fromtext {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 12px;
      /* or 86% */
      // text-align: center;
      margin-left: 10px;
      // display: flex;
      align-items: flex-end;
      letter-spacing: -1px;

      color: #000000;
    }
    .componenttext {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 48px;
      /* identical to box height, or 311% */
      margin-right: 10px;
      display: flex;
      align-items: flex-end;
      letter-spacing: -1px;
      text-transform: capitalize;
      margin-bottom: 20px;
      color: #565656;
    }
    .secondBox {
      width: 50px;
      height: 50px;
      text-align: center;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      margin: 0px 10px;
    }
    .default {
      margin-left: 48px;
      margin-top: 13px;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 12px;
      /* or 86% */

      display: flex;
      align-items: flex-end;
      text-align: center;
      // letter-spacing: -1px;

      color: #5b5b5b;
    }
    .addComp {
      background: #ffffff;
      box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      width: 100%;
      height: 56px;
      cursor: pointer;
      position: relative;
      margin-top: 10px;
      text-align: left;
      padding-left: 24px;
      .text {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 56px;
        cursor: pointer;
        /* identical to box height, or 311% */

        // display: flex;
        align-items: flex-end;
        letter-spacing: -1px;

        color: #565656;
      }
    }
    .saveBtn {
      background: #bdbdbd;
      text-transform: capitalize;

      box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
        0px 16px 32px rgba(38, 50, 56, 0.08);
      border-radius: 16px;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 36px;
      width: 106%;
      margin-top: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.25px;

      color: #ffffff;

      text-shadow: 0px 2px 4px rgba(38, 50, 56, 0.16),
        0px 4px 8px rgba(38, 50, 56, 0.08);
      &:hover {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
          0 1px 2px 0 rgba(0, 0, 0, 0.06);
        transition: 0.3s;
        background-color: rgba(146, 180, 211, 0.8);
        color: #fff;
      }
    }
    .chatBoxEdit {
      // padding-left: 48px;
      margin-top: 30px;
      p {
        font-family: Inter;
        padding-left: 48px;

        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        cursor: pointer;
        display: flex;
        align-items: flex-end;
        letter-spacing: -1px;

        color: #565656;
      }
      span {
        font-family: Inter;
        padding-left: 48px;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        text-transform: capitalize;
        display: flex;
        align-items: flex-end;
        letter-spacing: -1px;
        cursor: pointer;
        margin-bottom: 20px;
        color: #565656;
      }
      .chatBox {
        height: 170px;
        width: 105%;
        background: #ffffff;
        border: 0.5px solid #000000;
        box-sizing: border-box;
        // box-shadow: inset 1px 2px 5px rgba(0 0 0 / 10%);
        border-radius: 15px;
        padding: 10px;
        outline: none;
        &:focus {
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
            0 1px 2px 0 rgba(0, 0, 0, 0.06);
          transition: 0.3s;
          border-color: rgba(146, 180, 211, 0.8);
        }
      }
      .chatBoxResp {
        height: 170px;
        width: 100%;
        background: #ffffff;
        border: 0.5px solid #000000;
        box-sizing: border-box;
        // box-shadow: inset 1px 2px 5px rgba(0 0 0 / 10%);
        border-radius: 15px;
        padding: 10px;
        outline: none;
        &:focus {
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
            0 1px 2px 0 rgba(0, 0, 0, 0.06);
          transition: 0.3s;
          border-color: rgba(146, 180, 211, 0.8);
        }
      }
    }
  }
}
.modalWrapper {
  text-align: center;
  .contentWrap {
    width: 80%;
    margin: auto;
    margin-top: 15px;
    h4 {
      padding: 9px;
      font-size: 21px;
      margin-top: 22px;
    }
    p {
      text-align: left;
      margin-bottom: 10px;
      padding-left: 7px;
      font-size: 14px;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      .uploadbtn {
        border: 1px solid #bdbdbd;
        width: 100%;
        margin-top: 21px;
        text-transform: capitalize;
        background: #bdbdbd73;
      }
    }
  }
  .modalHead {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 32px;
    /* identical to box height, or 229% */

    text-align: center;

    color: #000000;
  }
  .modalBox {
    width: 105px;
    height: 105px;
    margin: 10px auto;
    // background: #ff0000;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  .inputTextWrap {
    display: inline-flex;
    margin-top: 10px;
    margin-left: 25px;
  }
  .inputTextWrap2 {
    display: inline-flex;
    margin-top: 10px;
    margin-left: 25px;
  }
  .modalInput {
    // width: 189px;
    width: 96%;

    height: 37px;
    border: 1px solid #ddd;
    background: #ffffff;
    box-shadow: inset 2px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    outline: none;
    padding-left: 10px;
  }
  .modalText {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 32px;
    /* identical to box height, or 229% */
    margin-left: -26px;
    margin-right: 10px;
    cursor: pointer;
    color: #000000;
  }
  .modalMaterialHead {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 32px;
    margin-bottom: 10px;
    /* identical to box height, or 229% */
    margin-top: 12px;
    text-align: center;
    cursor: pointer;
    border-bottom: 1px solid rgb(229, 225, 225);

    color: #000000;
  }
  .uploadbtnswatch {
    width: 160px;
    height: 36px;
    text-transform: capitalize;
    background: #ffffff;
    border: 1px solid #000000;
    box-sizing: border-box;
    box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1);
    border-radius: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 36px;
    /* or 257% */
    margin: 20px auto;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.25px;
    color: #616161;
  }
  .uploadbtnswatchPicker {
    width: 160px;
    height: 36px;
    text-transform: capitalize;
    background: #ffffff;
    border: 1px solid #000000;
    box-sizing: border-box;
    box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1);
    border-radius: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 36px;
    /* or 257% */
    margin: 20px auto;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.25px;
    color: #616161;
    position: relative;
    bottom: 250px;
  }
  .MuiInput-underline:before {
    border-bottom: none;
  }
  .addBtn {
    background: #bdbdbd;
    // box-shadow: 0px 8px 24px rgba(38 50 56 / 10%), 0px 16px 32px rgb(38 50 56 / 8%);
    border-radius: 6px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    align-items: center;
    text-align: center;
    margin-left: 19px;
    letter-spacing: 0.25px;
    margin-top: 20px;
    width: 61%;
    padding: 8px;
    text-transform: capitalize;
    color: #ffffff;
    &:hover {
      transition: 0.3s;
      background-color: rgba(146, 180, 211, 0.8);
      color: #fff;

      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.06);
    }
  }
  .varticalLine {
    width: 1px;
    position: absolute;
    left: 50%;
    height: 33px;
  }
}
.confirmationModal {
  text-align: center;
  padding: 34px;
  justify-content: center;
  align-items: center;
  padding-top: 90px;
  .DeleteBtn {
    background-color: #ea4747;
    padding: 3px 10px;
    border-radius: 6px;
    color: #fff;
    margin: 10px;
    width: 30%;
    height: 36px;
    text-transform: capitalize;
    &:hover {
      transition: 0.3s;
      background-color: rgba(146, 180, 211, 0.8);
      color: #fff;

      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.06);
    }
  }
}
.selected {
  border-bottom: 3px solid #828282;
  // width: 100px;
}
.productUpload {
  display: flex;
  justify-content: center;
  align-items: center;
  .editRightWrapper {
    text-align: left;
    .inlineWrapper {
      display: inline-flex;
      flex-wrap: wrap;
    }
    //   .parentDiv{
    //     width: 100%;
    //     height: 100%;
    //     overflow: hidden;
    // }
    ::-webkit-scrollbar {
      width: 0px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #ddd;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .box1 {
      // width: 308px;
      // height: 88px;

      box-sizing: content-box;
      padding: 15px;
      padding-bottom: 20px;
      margin-bottom: 10px;
      background: #ffffff;
      box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      position: relative;
      .Input {
        width: 96%;
        height: 37px;
        margin-top: 10px;
        border: 1px solid #ddd;
        background: #ffffff;
        // box-shadow: inset 2px 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        outline: none;
        padding-left: 10px;
      }
    }
    .downArrow {
      position: absolute;
      right: 10px;
      top: 10px;
      color: #bdbdbd;
      font-size: 30px;
    }
    .smallBox {
      width: 50px;
      height: 50px;
      margin: 0px 10px;
      background: #e2e2e2;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      text-align: center;
      margin-bottom: 17px;
    }
    .compName {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 12px;
      margin-left: 10px;
      margin-bottom: 10px;
      display: flex;
      align-items: flex-end;
      letter-spacing: -1px;

      color: #000000;
    }
    .buyNow {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 24px;
      margin-left: 0px;
      display: flex;
      align-items: flex-end;
      letter-spacing: -1px;
      margin-bottom: 10px;
      color: #000000;
      margin-top: 10px;
    }
    .fromtext {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 12px;
      /* or 86% */
      // text-align: center;
      margin-left: 10px;
      // display: flex;
      align-items: flex-end;
      letter-spacing: -1px;

      color: #000000;
    }
    .componenttext {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 56px;
      /* identical to box height, or 311% */
      margin-right: 10px;
      display: flex;
      align-items: flex-end;
      letter-spacing: -1px;
      text-transform: capitalize;

      color: #565656;
    }
    .secondBox {
      width: 50px;
      height: 50px;
      text-align: center;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      margin: 0px 10px;
    }
    .default {
      margin-left: 48px;
      margin-top: 13px;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 12px;
      /* or 86% */

      display: flex;
      align-items: flex-end;
      text-align: center;
      letter-spacing: -1px;

      color: #5b5b5b;
    }
    .addComp {
      background: #ffffff;
      box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      width: 328px;
      height: 56px;
      cursor: pointer;
      position: relative;
      margin-top: 10px;
      text-align: left;
      padding-left: 24px;
      .text {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 56px;
        cursor: pointer;
        /* identical to box height, or 311% */

        // display: flex;
        align-items: flex-end;
        letter-spacing: -1px;

        color: #565656;
      }
    }
    .saveBtn {
      background: #bdbdbd;

      box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
        0px 16px 32px rgba(38, 50, 56, 0.08);
      border-radius: 16px;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 36px;
      width: 100%;
      margin-top: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.25px;
      text-transform: capitalize;
      color: #ffffff;
      text-transform: capitalize;
      text-shadow: 0px 2px 4px rgba(38, 50, 56, 0.16),
        0px 4px 8px rgba(38, 50, 56, 0.08);
      &:hover {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
          0 1px 2px 0 rgba(0, 0, 0, 0.06);
        transition: 0.3s;
        background-color: rgba(146, 180, 211, 0.8);
        color: #fff;
      }
    }
  }
  .PreeditWrapper {
    text-align: center;
    .addIcon {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 72px;
      line-height: 36px;

      align-items: center;
      text-align: center;
      letter-spacing: 0.25px;
      margin-bottom: 39px;
      color: #bdbdbd;
    }
    .uploadBtn {
      background: #bdbdbd;
      color: white;
      box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
        0px 16px 32px rgba(38, 50, 56, 0.08);
      border-radius: 16px;
      height: 54px;
      width: 200px;
      margin-bottom: 20px;
      text-transform: capitalize;
      &:hover {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
          0 1px 2px 0 rgba(0, 0, 0, 0.06);
        transition: 0.3s;
        background-color: rgba(146, 180, 211, 0.8);
        color: #fff;
      }
    }
    .acceptedText {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 32px;
      /* identical to box height, or 178% */

      text-align: center;

      /* Gray 3 */

      color: #828282;
      margin-bottom: 10px;
    }
    .normalText {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 32px;
      /* or 178% */
      margin-bottom: 20px;
      padding: 10px;
      text-align: center;

      /* Gray 3 */

      color: #828282;
    }
    .OrText {
      position: absolute;
      left: 48%;
      top: -13px;
      background: #fff;
      color: #828282;
      width: 36px;
    }
    .hrLine {
      border-bottom: 1px solid #a4a4a4;
    }
  }
}

.paymentsettings {
  margin-top: 30px !important;
  .sidebar {
    text-align: center;
    .sidebarItem {
      background: #ebebeb;
      width: 123px;
      height: 123px;
      display: inline-flex;
      border-radius: 16px;
    }
    .nameComp {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 48px;
      border-bottom: 0.4px solid #bdbdbd;
      padding-bottom: 10px;
      align-items: center;
      text-align: center;
      letter-spacing: -0.5px;

      /* Black */
      margin-top: 10px;

      color: #2d3436;
    }
    .performance {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 36px;
      /* identical to box height, or 300% */

      display: flex;
      align-items: center;
      letter-spacing: 0.25px;

      /* Black */

      color: #2d3436;

      /* Inside Auto Layout */

      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 1.5px 0px;
    }
    .spanTag {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 32px;
      cursor: pointer;
      /* or 229% */

      /* Black */

      color: #2d3436;

      /* Inside Auto Layout */

      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 14px 0px;
    }
    .productBtn {
      background: #ebebeb;
      border-radius: 15px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 48px;
      text-transform: capitalize;
      /* identical to box height, or 267% */
      width: 90%;
      // display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.5px;

      color: #666666;
      &:hover {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
          0 1px 2px 0 rgba(0, 0, 0, 0.06);
        transition: 0.3s;
        background-color: rgba(146, 180, 211, 0.8);
        color: #fff;
      }
    }
    .onHoverLink {
      text-decoration: none;
      &:hover {
        border-bottom: 1px solid rgba(146, 180, 211, 0.8);
        transition: 0.3s;
      }
    }
  }
  .SettingBtn {
    background: #bdbdbd;
    border-radius: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 48px;
    /* identical to box height, or 267% */
    width: 25%;
    // display: flex;
    align-items: center;
    text-transform: capitalize;
    position: relative;
    text-align: center;
    letter-spacing: -0.5px;
    box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
      0px 16px 32px rgba(38, 50, 56, 0.08);
    margin-bottom: 30px;
    color: #666666;
    &:hover {
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.06);
      transition: 0.3s;
      background-color: rgba(146, 180, 211, 0.8);
      color: #fff;
    }
  }
  .mainText {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */
    padding: 0px 0px 10px 0px;
    align-items: center;
    border-bottom: 1px solid #ddd;
    color: #000000;
    position: relative;
  }
  .masterCard {
    background: #c4c4c4;
    border-radius: 7px;
    width: 82px;
    height: 45px;
  }
}
.shareModal {
  padding: 25px;
  .textMain {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -1px;
    color: #565656;
    display: inline-block;
    margin-bottom: 24px;
  }
  .input {
    border: 0.5px solid #000000;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 8px;
    width: 100%;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    outline: none;
  }
  .CopyBtn {
    background: #c4c4c4;
    border-radius: 7px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    /* identical to box height, or 133% */
    position: absolute;
    top: 10px;
    right: -1px;
    display: flex;
    align-items: flex-end;
    letter-spacing: -1px;
    text-transform: capitalize;

    color: #ffffff;
    &:hover {
      transition: 0.3s;
      background-color: rgba(146, 180, 211, 0.8);
      color: #fff;

      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.06);
    }
  }
}
.selectedLink {
  color: #2874f0;
  border-bottom: 5px solid #efefef;
  font-weight: 900;
}
.privacyPolicy {
  // text-align: center;
  padding: 50px;
  .descripttag {
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    padding: 0px 10px;
    align-items: center;
    text-align: center;
    color: #585656;
  }
}
.blog_detail {
  padding: 40px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  .blogHead {
    font-style: normal;
    font-weight: bold;
    text-align: center;
    line-height: 48px;
    letter-spacing: -0.5px;
    margin-bottom: 40px;
    padding: 20px;
    font-size: 70px;
  }
  .blogHeadResp {
    font-style: normal;
    font-weight: bold;
    text-align: center;
    line-height: 100px;
    letter-spacing: -0.5px;
    margin-bottom: 40px;
    padding: 20px;
    font-size: 70px;
  }
  .descript {
    text-transform: capitalize;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: #2d3436;
    cursor: pointer;
    text-align: left;
    padding-left: 50px;
  }
  .blogImaage {
    width: 100%;
    height: 300px;
    border-radius: 8px;
  }
}
@media only screen and (min-width: 365px) and (max-width: 767px) {
  .web {
    display: none;
  }
  .responsive {
    display: block;
  }

  .shareModal {
    padding: 25px;
    .MuiButton-root {
      min-width: 51px;
    }
    .respHamburger {
      top: 66px !important;
      width: 43px;
      height: 10px;
      position: absolute;
      top: 78px;
      right: 21px;
      z-index: 2147483648;
      display: inline-flex;
    }
    .textMain {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -1px;
      color: #565656;
      display: inline-block;
      margin-bottom: 24px;
    }
    .input {
      border: 0.5px solid #000000;
      box-sizing: border-box;
      border-radius: 2px;
      padding: 8px;
      width: 100%;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      outline: none;
    }
    .CopyBtn {
      background: #c4c4c4;
      border-radius: 7px;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 24px;
      /* identical to box height, or 133% */

      display: flex;
      align-items: flex-end;
      letter-spacing: -1px;
      text-transform: capitalize;

      color: #ffffff;
    }
  }
  .productList {
    .rowheads {
      font-size: 13px !important;
    }
  }
}

body {
  ::-webkit-scrollbar {
    width: 1px;
    height: 1px;
    border: 1px solid #d5d5d5;
  }
}
