/* All classes used must begin with "__se__". */
/* used color #000 , #fff , #CCC, #f9f9f9 */
/* font color #333, background color: #fff */
/* grey color #e1e1e1 , #d1d1d1 , #c1c1c1 , #b1b1b1 */
/* blue color #c7deff , #80bdff , #3f9dff , #4592ff */
/* red color #b94a48 , #f2dede , #eed3d7 */

/* suneditor contents */
.__ACTUALITY_SUN_EDITOR_CONTENT {
  font-family: Helvetica Neue;
  font-size: 13px;
  color: #333;
  background-color: #fff;
  line-height: 1.5;
  word-break: normal;
  word-wrap: break-word;
  /* padding: 16px; */
  margin: 0;
  /* border: 2px solid red !important; */
}

.__ACTUALITY_SUN_EDITOR_CONTENT * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
}

/* RTL - editable */
.__ACTUALITY_SUN_EDITOR_CONTENT.se-rtl * {
  direction: rtl;
}

/** controllers on tag */
.__ACTUALITY_SUN_EDITOR_CONTENT td,
.__ACTUALITY_SUN_EDITOR_CONTENT th,
.__ACTUALITY_SUN_EDITOR_CONTENT figure,
.__ACTUALITY_SUN_EDITOR_CONTENT figcaption,
.__ACTUALITY_SUN_EDITOR_CONTENT img,
.__ACTUALITY_SUN_EDITOR_CONTENT iframe,
.__ACTUALITY_SUN_EDITOR_CONTENT video,
.__ACTUALITY_SUN_EDITOR_CONTENT audio {
  position: relative;
}

/* float */
.__ACTUALITY_SUN_EDITOR_CONTENT .__se__float-left {
  float: left;
}
.__ACTUALITY_SUN_EDITOR_CONTENT .__se__float-right {
  float: right;
}
.__ACTUALITY_SUN_EDITOR_CONTENT .__se__float-center {
  float: center;
}
.__ACTUALITY_SUN_EDITOR_CONTENT .__se__float-none {
  float: none;
}

/** span */
.__ACTUALITY_SUN_EDITOR_CONTENT span {
  display: inline;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
}
/* katex */
.__ACTUALITY_SUN_EDITOR_CONTENT span.katex {
  display: inline-block;
}
.__ACTUALITY_SUN_EDITOR_CONTENT span.katex * {
  direction: ltr;
}

/* a */
.__ACTUALITY_SUN_EDITOR_CONTENT a {
  color: #004cff;
  text-decoration: none;
}
.__ACTUALITY_SUN_EDITOR_CONTENT span[style~='color:'] a {
  color: inherit;
}
.__ACTUALITY_SUN_EDITOR_CONTENT a:hover,
.__ACTUALITY_SUN_EDITOR_CONTENT a:focus {
  cursor: pointer;
  color: #0093ff;
  text-decoration: underline;
}
.__ACTUALITY_SUN_EDITOR_CONTENT a.on {
  color: #0093ff;
  background-color: #e8f7ff;
}

/* pre */
.__ACTUALITY_SUN_EDITOR_CONTENT pre {
  display: block;
  padding: 8px;
  margin: 0 0 10px;
  font-family: monospace;
  color: #666;
  line-height: 1.45;
  background-color: #f9f9f9;
  border: 1px solid #e1e1e1;
  border-radius: 2px;
  white-space: pre-wrap !important;
  word-wrap: break-word;
  overflow: visible;
}

/* ol, ul */
.__ACTUALITY_SUN_EDITOR_CONTENT ol {
  list-style-position: outside;
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.__ACTUALITY_SUN_EDITOR_CONTENT ul {
  list-style-position: outside;
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.__ACTUALITY_SUN_EDITOR_CONTENT li {
  display: list-item;
  text-align: -webkit-match-parent;
  margin-bottom: 5px;
}
/* nested list ol, ul */
.__ACTUALITY_SUN_EDITOR_CONTENT ol ol,
.__ACTUALITY_SUN_EDITOR_CONTENT ol ul,
.__ACTUALITY_SUN_EDITOR_CONTENT ul ol,
.__ACTUALITY_SUN_EDITOR_CONTENT ul ul {
  margin: 0;
}
.__ACTUALITY_SUN_EDITOR_CONTENT ol ol,
.__ACTUALITY_SUN_EDITOR_CONTENT ul ol {
  list-style-type: lower-alpha;
}
.__ACTUALITY_SUN_EDITOR_CONTENT ol ol ol,
.__ACTUALITY_SUN_EDITOR_CONTENT ul ol ol,
.__ACTUALITY_SUN_EDITOR_CONTENT ul ul ol {
  list-style-type: upper-roman;
}
.__ACTUALITY_SUN_EDITOR_CONTENT ul ul,
.__ACTUALITY_SUN_EDITOR_CONTENT ol ul {
  list-style-type: circle;
}
.__ACTUALITY_SUN_EDITOR_CONTENT ul ul ul,
.__ACTUALITY_SUN_EDITOR_CONTENT ol ul ul,
.__ACTUALITY_SUN_EDITOR_CONTENT ol ol ul {
  list-style-type: square;
}

/* sub, sup */
.__ACTUALITY_SUN_EDITOR_CONTENT sub,
.__ACTUALITY_SUN_EDITOR_CONTENT sup {
  font-size: 75%;
  line-height: 0;
}
.__ACTUALITY_SUN_EDITOR_CONTENT sub {
  vertical-align: sub;
}
.__ACTUALITY_SUN_EDITOR_CONTENT sup {
  vertical-align: super;
}

/** format style */
/* p */
.__ACTUALITY_SUN_EDITOR_CONTENT p {
  display: block;
  margin: 0 0 10px;
}

/* div */
.__ACTUALITY_SUN_EDITOR_CONTENT div {
  display: block;
  margin: 0;
  padding: 0;
}

/* blockquote */
.__ACTUALITY_SUN_EDITOR_CONTENT blockquote {
  display: block;
  font-family: inherit;
  font-size: inherit;
  color: #999;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  border-style: solid;
  border-width: 0;
  padding-top: 0;
  padding-bottom: 0;
  border-color: #b1b1b1;
  padding-left: 20px;
  padding-right: 5px;
  border-left-width: 5px;
  border-right-width: 0px;
}
.__ACTUALITY_SUN_EDITOR_CONTENT blockquote blockquote {
  border-color: #c1c1c1;
}
.__ACTUALITY_SUN_EDITOR_CONTENT blockquote blockquote blockquote {
  border-color: #d1d1d1;
}
.__ACTUALITY_SUN_EDITOR_CONTENT blockquote blockquote blockquote blockquote {
  border-color: #e1e1e1;
}
/* RTL - blockquote */
.__ACTUALITY_SUN_EDITOR_CONTENT.se-rtl blockquote {
  padding-left: 5px;
  padding-right: 20px;
  border-left-width: 0px;
  border-right-width: 5px;
}

/* h1 */
.__ACTUALITY_SUN_EDITOR_CONTENT h1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
/* h2 */
.__ACTUALITY_SUN_EDITOR_CONTENT h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
/* h3 */
.__ACTUALITY_SUN_EDITOR_CONTENT h3 {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
/* h4 */
.__ACTUALITY_SUN_EDITOR_CONTENT h4 {
  display: block;
  font-size: 1em;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
/* h5 */
.__ACTUALITY_SUN_EDITOR_CONTENT h5 {
  display: block;
  font-size: 0.83em;
  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
/* h6 */
.__ACTUALITY_SUN_EDITOR_CONTENT h6 {
  display: block;
  font-size: 0.67em;
  margin-block-start: 2.33em;
  margin-block-end: 2.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

/* hr */
.__ACTUALITY_SUN_EDITOR_CONTENT hr {
  display: flex;
  border-width: 1px 0 0;
  border-color: black;
  border-image: initial;
  height: 1px;
}
.__ACTUALITY_SUN_EDITOR_CONTENT hr.__se__solid {
  border-style: solid none none;
}
.__ACTUALITY_SUN_EDITOR_CONTENT hr.__se__dotted {
  border-style: dotted none none;
}
.__ACTUALITY_SUN_EDITOR_CONTENT hr.__se__dashed {
  border-style: dashed none none;
}
.__ACTUALITY_SUN_EDITOR_CONTENT hr.on {
  border-color: #4592ff;
  -webkit-box-shadow: 0 0 0 0.1rem #c7deff;
  box-shadow: 0 0 0 0.1rem #c7deff;
}

/* table */
.__ACTUALITY_SUN_EDITOR_CONTENT table {
  display: table;
  table-layout: auto !important;
  border: 1px solid rgb(204, 204, 204);
  width: 100%;
  max-width: 100%;
  margin: 0 0 10px;
  background-color: transparent;
  border-spacing: 0;
  border-collapse: collapse;
}

/* RTL - table */
.__ACTUALITY_SUN_EDITOR_CONTENT.se-rtl table {
  margin: 0 0 10px auto;
}

.__ACTUALITY_SUN_EDITOR_CONTENT table thead {
  border-bottom: 2px solid #333;
}
.__ACTUALITY_SUN_EDITOR_CONTENT table tr {
  border: 1px solid #efefef;
}
.__ACTUALITY_SUN_EDITOR_CONTENT table th {
  background-color: #f3f3f3;
}
.__ACTUALITY_SUN_EDITOR_CONTENT table th,
.__ACTUALITY_SUN_EDITOR_CONTENT table td {
  border: 1px solid #e1e1e1;
  padding: 0.4em;
  background-clip: padding-box;
}
/** table style class */
.__ACTUALITY_SUN_EDITOR_CONTENT table.se-table-size-auto {
  width: auto !important;
}
.__ACTUALITY_SUN_EDITOR_CONTENT table.se-table-size-100 {
  width: 100% !important;
}
.__ACTUALITY_SUN_EDITOR_CONTENT table.se-table-layout-auto {
  table-layout: auto !important;
}
.__ACTUALITY_SUN_EDITOR_CONTENT table.se-table-layout-fixed {
  table-layout: fixed !important;
}
/** table - select class */
.__ACTUALITY_SUN_EDITOR_CONTENT table td.se-table-selected-cell,
.__ACTUALITY_SUN_EDITOR_CONTENT table th.se-table-selected-cell {
  outline: 1px double #4592ff;
}
.__ACTUALITY_SUN_EDITOR_CONTENT.se-disabled * {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

/** component (image, iframe video) */
.__ACTUALITY_SUN_EDITOR_CONTENT .se-component {
  display: flex;
  padding: 1px;
  margin: 0 0 10px;
}
.__ACTUALITY_SUN_EDITOR_CONTENT[contenteditable='true'] .se-component {
  outline: 1px dashed #e1e1e1;
}
/** component copy effect */
.__ACTUALITY_SUN_EDITOR_CONTENT[contenteditable='true']
  .se-component.se-component-copy {
  -webkit-box-shadow: 0 0 0 0.2rem #80bdff;
  box-shadow: 0 0 0 0.2rem #3f9dff;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/** image, video .. */
.__ACTUALITY_SUN_EDITOR_CONTENT img,
.__ACTUALITY_SUN_EDITOR_CONTENT iframe,
.__ACTUALITY_SUN_EDITOR_CONTENT video,
.__ACTUALITY_SUN_EDITOR_CONTENT audio {
  display: block;
  margin: 0;
  padding: 0;
  width: auto;
  height: auto;
  max-width: 100%;
}
/*  image, video - select index  */
.__ACTUALITY_SUN_EDITOR_CONTENT[contenteditable='true']:not(.se-read-only)
  figure::after {
  position: absolute;
  content: '';
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: default;
  display: block;
  background: rgba(0, 0, 0, 0);
}
.__ACTUALITY_SUN_EDITOR_CONTENT[contenteditable='true'] figure a,
.__ACTUALITY_SUN_EDITOR_CONTENT[contenteditable='true'] figure img,
.__ACTUALITY_SUN_EDITOR_CONTENT[contenteditable='true'] figure iframe,
.__ACTUALITY_SUN_EDITOR_CONTENT[contenteditable='true'] figure video {
  z-index: 0;
}
.__ACTUALITY_SUN_EDITOR_CONTENT[contenteditable='true'] figure figcaption {
  display: block;
  z-index: 2;
}
.__ACTUALITY_SUN_EDITOR_CONTENT[contenteditable='true']
  figure
  figcaption:focus {
  border-color: #80bdff;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem #c7deff;
  box-shadow: 0 0 0 0.2rem #c7deff;
}

/** image, video iframe figure cover */
.__ACTUALITY_SUN_EDITOR_CONTENT .se-image-container,
.__ACTUALITY_SUN_EDITOR_CONTENT .se-video-container {
  width: auto;
  height: auto;
  max-width: 100%;
}
.__ACTUALITY_SUN_EDITOR_CONTENT figure {
  display: block;
  outline: none;
  margin: 0;
  padding: 0;
}
.__ACTUALITY_SUN_EDITOR_CONTENT figure figcaption {
  padding: 1em 0.5em;
  margin: 0;
  background-color: #f9f9f9;
  outline: none;
}
.__ACTUALITY_SUN_EDITOR_CONTENT figure figcaption p {
  line-height: 2;
  margin: 0;
}

/* image */
.__ACTUALITY_SUN_EDITOR_CONTENT .se-image-container a img {
  padding: 1px;
  margin: 1px;
  outline: 1px solid #4592ff;
}

/** video */
.__ACTUALITY_SUN_EDITOR_CONTENT .se-video-container iframe,
.__ACTUALITY_SUN_EDITOR_CONTENT .se-video-container video {
  outline: 1px solid #9e9e9e;
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  width: 100%;
  height: 100%;
}
.__ACTUALITY_SUN_EDITOR_CONTENT .se-video-container figure {
  left: 0px;
  width: 100%;
  max-width: 100%;
}

/** audio */
.__ACTUALITY_SUN_EDITOR_CONTENT audio {
  width: 300px;
  height: 54px;
}
.__ACTUALITY_SUN_EDITOR_CONTENT audio.active {
  outline: 2px solid #80bdff;
}

/** --- show blocks mode */
.__ACTUALITY_SUN_EDITOR_CONTENT.se-show-block p,
.__ACTUALITY_SUN_EDITOR_CONTENT.se-show-block div,
.__ACTUALITY_SUN_EDITOR_CONTENT.se-show-block h1,
.__ACTUALITY_SUN_EDITOR_CONTENT.se-show-block h2,
.__ACTUALITY_SUN_EDITOR_CONTENT.se-show-block h3,
.__ACTUALITY_SUN_EDITOR_CONTENT.se-show-block h4,
.__ACTUALITY_SUN_EDITOR_CONTENT.se-show-block h5,
.__ACTUALITY_SUN_EDITOR_CONTENT.se-show-block h6,
.__ACTUALITY_SUN_EDITOR_CONTENT.se-show-block li,
.__ACTUALITY_SUN_EDITOR_CONTENT.se-show-block ol,
.__ACTUALITY_SUN_EDITOR_CONTENT.se-show-block ul,
.__ACTUALITY_SUN_EDITOR_CONTENT.se-show-block pre {
  border: 1px dashed #3f9dff !important;
  padding: 14px 8px 8px 8px !important;
}
.__ACTUALITY_SUN_EDITOR_CONTENT.se-show-block ol,
.__ACTUALITY_SUN_EDITOR_CONTENT.se-show-block ul {
  border: 1px dashed #d539ff !important;
}
.__ACTUALITY_SUN_EDITOR_CONTENT.se-show-block pre {
  border: 1px dashed #27c022 !important;
}
.se-show-block p {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAPAQMAAAAF7dc0AAAABlBMVEWAgID////n1o2sAAAAAnRSTlP/AOW3MEoAAAAaSURBVAjXY/j/gwGCPvxg+F4BQiAGDP1HQQByxxw0gqOzIwAAAABJRU5ErkJggg==')
    no-repeat;
}
.se-show-block div {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAPAQMAAAAxlBYoAAAABlBMVEWAgID////n1o2sAAAAAnRSTlP/AOW3MEoAAAAmSURBVAjXY/j//wcDDH+8XsHwDYi/hwNx1A8w/nYLKH4XoQYJAwCXnSgcl2MOPgAAAABJRU5ErkJggg==')
    no-repeat;
}
.se-show-block h1 {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAPAQMAAAA4f7ZSAAAABlBMVEWAgID////n1o2sAAAAAnRSTlP/AOW3MEoAAAAfSURBVAjXY/j/v4EBhr+9B+LzEPrDeygfhI8j1CBhAEhmJGY4Rf6uAAAAAElFTkSuQmCC')
    no-repeat;
}
.se-show-block h2 {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAPAQMAAAA4f7ZSAAAABlBMVEWAgID////n1o2sAAAAAnRSTlP/AOW3MEoAAAAmSURBVAjXY/j/v4EBhr+dB+LtQPy9geEDEH97D8T3gbgdoQYJAwA51iPuD2haEAAAAABJRU5ErkJggg==')
    no-repeat;
}
.se-show-block h3 {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAPAQMAAAA4f7ZSAAAABlBMVEWAgID////n1o2sAAAAAnRSTlP/AOW3MEoAAAAiSURBVAjXY/j/v4EBhr+dB+LtQPy9geHDeQgN5p9HqEHCADeWI+69VG2MAAAAAElFTkSuQmCC')
    no-repeat;
}
.se-show-block h4 {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAPAQMAAADTSA1RAAAABlBMVEWAgID////n1o2sAAAAAnRSTlP/AOW3MEoAAAAiSURBVAjXY/j//wADDH97DsTXIfjDdiDdDMTfIRhZHRQDAKJOJ6L+K3y7AAAAAElFTkSuQmCC')
    no-repeat;
}
.se-show-block h5 {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAPAQMAAAA4f7ZSAAAABlBMVEWAgID////n1o2sAAAAAnRSTlP/AOW3MEoAAAAlSURBVAjXY/j/v4EBhr+1A/F+IO5vYPiwHUh/B2IQfR6hBgkDABlWIy5uM+9GAAAAAElFTkSuQmCC')
    no-repeat;
}
.se-show-block h6 {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAPAQMAAAA4f7ZSAAAABlBMVEWAgID////n1o2sAAAAAnRSTlP/AOW3MEoAAAAiSURBVAjXY/j/v4EBhr+dB+LtQLy/geFDP5S9HSKOrA6KAR9GIza1ptJnAAAAAElFTkSuQmCC')
    no-repeat;
}
.se-show-block li {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAA7SURBVDhPYxgFcNDQ0PAfykQBIHEYhgoRB/BpwCfHBKWpBkaggYxQGgOgBzyQD1aLLA4TGwWDGjAwAACR3RcEU9Ui+wAAAABJRU5ErkJggg==')
    no-repeat;
}
.se-show-block ol {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAABHSURBVDhPYxgFcNDQ0PAfhKFcFIBLHCdA1oBNM0kGEmMAPgOZoDTVANUNxAqQvURMECADRiiNAWCagDSGGhyW4DRrMAEGBgAu0SX6WpGgjAAAAABJRU5ErkJggg==')
    no-repeat;
}
.se-show-block ul {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAA1SURBVDhPYxgFDA0NDf+hTBSALI5LDQgwQWmqgVEDKQcsUBoF4ItFGEBXA+QzQpmDGjAwAAA8DQ4Lni6gdAAAAABJRU5ErkJggg==')
    no-repeat;
}

/** -- Paragraph styles custom -- */
/* Bordered */
.__ACTUALITY_SUN_EDITOR_CONTENT .__se__p-bordered,
.sun-editor .__se__p-bordered {
  border-top: solid 1px #b1b1b1;
  border-bottom: solid 1px #b1b1b1;
  padding: 4px 0;
}
/* Spaced */
.__ACTUALITY_SUN_EDITOR_CONTENT .__se__p-spaced,
.sun-editor .__se__p-spaced {
  letter-spacing: 1px;
}
/* Neon (https://codepen.io/GeorgePark/pen/MrjbEr) */
.__ACTUALITY_SUN_EDITOR_CONTENT .__se__p-neon,
.sun-editor .__se__p-neon {
  font-weight: 200;
  font-style: italic;
  background: #000;
  color: #fff;
  padding: 6px 4px;
  border: 2px solid #fff;
  border-radius: 6px;
  text-transform: uppercase;
  animation: neonFlicker 1.5s infinite alternate;
}
@keyframes neonFlicker {
  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  100% {
    text-shadow: -0.2rem -0.2rem 1rem #fff, 0.2rem 0.2rem 1rem #fff,
      0 0 2px #f40, 0 0 4px #f40, 0 0 6px #f40, 0 0 8px #f40, 0 0 10px #f40;
    box-shadow: 0 0 0.5px #fff, inset 0 0 0.5px #fff, 0 0 2px #08f,
      inset 0 0 2px #08f, 0 0 4px #08f, inset 0 0 4px #08f;
  }
  20%,
  24%,
  55% {
    text-shadow: none;
    box-shadow: none;
  }
}

/* -- Text styles custom -- */
/* Shadow */
.__ACTUALITY_SUN_EDITOR_CONTENT .__se__t-shadow,
.sun-editor .__se__t-shadow {
  text-shadow: -0.2rem -0.2rem 1rem #fff, 0.2rem 0.2rem 1rem #fff,
    0 0 0.2rem #999, 0 0 0.4rem #888, 0 0 0.6rem #777, 0 0 0.8rem #666,
    0 0 1rem #555;
}
/* Code */
.__ACTUALITY_SUN_EDITOR_CONTENT .__se__t-code,
.sun-editor .__se__t-code {
  font-family: monospace;
  color: #666;
  background-color: rgba(27, 31, 35, 0.05);
  border-radius: 6px;
  padding: 0.2em 0.4em;
}
