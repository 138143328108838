.rightBox {
  max-width: 120px;
  padding: 1rem 0;
  /* max-height: 145px; */
  /* height: 100%; */
  width: 100%;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 15px 0px 0px 15px;
  position: absolute;
  transform: translateY(-50%);
  right: 0;
  border: 0px solid red;

  transition: width 0.5s;
}
@media only screen and (max-width: 800px) {
  .rightBox {
    box-shadow: none;
    border-radius: 0;
  }
}

.content {
  height: 50%;
  border-bottom: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  justify-content: center;

  opacity: 1;
  transition: opacity 0.5s;
  overflow: hidden;
}

.rightBox.close {
  width: 24px;
}

.rightBox.close .content {
  opacity: 0;
}

.rightBox.close .rightBoxWrapper {
  /* width: 24px; */
}

.rightBoxWrapper {
  height: 100%;
  width: 100%;

  position: relative;
  padding: 0.5rem 5px 0.5rem 1.5rem;
  transition: width 0.5s;
}
