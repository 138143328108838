.SDM_COMPO_components {
  background-color: #fff;
  position: absolute;
  left: 0;
  flex-grow: 0;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 15px 15px 0px;
  max-height: 300px;
  margin-top: 0;
  border: 0px solid red;
  width: calc(56.28px + 3rem);
  transition: width 0.5s;
}

@media only screen and (max-width: 800px) {
  .SDM_COMPO_components {
    background-color: transparent !important;
    position: static;
    left: auto;
    top: auto;
    transform: none;
    width: 100%;
    box-shadow: none;
    border-radius: 0;
    max-height: 135px;
    /* margin-top: 1rem; */
    border: 0px solid red;
    width: 100%;
    /* border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd; */
    box-shadow: 0;
  }
}

.SDM_COMPO_components.close {
  width: 18px !important;
}

.SDM_COMPO_content {
  opacity: 1;
  transition: opacity 0.5s;
}
.SDM_COMPO_components,
.SDM_COMPO_content {
  scrollbar-width: 12px;
  scrollbar-color: black transparent;
  border: 0px solid red;
}

@media only screen and(max-width:440px) {
  body .SDM_COMPO_content::-webkit-scrollbar {
    width: 0 !important;
    display: none !important;
  }
  .SDM_COMPO_content::-webkit-scrollbar {
    width: 0px !important;
    display: none !important;
  }

  .SDM_COMPO_content::-webkit-scrollbar-thumb {
    background-color: black;
    display: none !important;
  }
  .SDM_COMPO_content::-webkit-scrollbar-track {
    background-color: transparent;
    display: none !important;
  }
}

/* @media only screen and(max-width:440px) {
  .SDM_COMPO_components,
  .SDM_COMPO_content {
    border: 2px solid red !important;
  }
  .SDM_COMPO_components::-webkit-scrollbar {
    display: none;
  }
  .SDM_COMPO_content::-webkit-scrollbar {
    display: none;
  }
} */
